import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import hash from "object-hash";
import { getFullDate } from "../components/general";
import Typography from "@mui/material/Typography";
import { nullConference } from "./general";
import { grey, red } from "@mui/material/colors";
import "../styles/react-datepicker.css";
import { useSelector } from "react-redux";
import ImageUpload from "./uploadImage-3";
import TextEditor from "./helpers/sunTextEditor";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import useStylesOld from "./style/textFields";
import { API, graphqlOperation } from "aws-amplify";
import { eventsbyCycle, getCycle, getEvent } from "../graphql/queries";
registerLocale("fr", fr);

export const ModalModifyConference = (props) => {
  return null;
};

const CycleForm = (props) => {
  const {
    formData,
    setFormData,
    setShowButton,
    secondScreenHook,
    selectionModel,
    setEventsCallback,
  } = props;
  // const modalStore = modalStoreHook[0]
  // const setModalStore = modalStoreHook[1]

  const classes = useStylesOld(props);
  const [prixError, setPrixError] = useState(false);
  const [nbMaxError, setNbMaxError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [events, setEvents] = useState([]);
  const eventsHook = useSelector((state) => state.eventsReducer);
  const cycles = useSelector((state) => state.cyclesReducer);

  const selectedIds = eventsHook.selectedRows;

  // var events = !secondScreenHook[0].cycleId ? eventsHook.data
  //   .filter(event => selectedIds.includes(event.id)) : eventsHook.data.filter(event => event.cycleId === secondScreenHook[0].cycleId)

  const cycle = cycles.data.find(
    (cycle) => cycle.id === secondScreenHook[0]?.cycleId
  );
  const cycleRef = useRef(cycle);

  const [newDocumentHtml, setNewDocumentHtml] = useState(null);

  useEffect(async () => {
    console.log("selectedIds", selectedIds);
    if (!secondScreenHook[0]?.cycleId) {
      const events = await Promise.all(
        selectedIds.map(async (id) => {
          const res = await API.graphql(
            graphqlOperation(getEvent, {
              id,
            })
          );
          return await res.data.getEvent;
        })
      );

      setEvents(events);
    } else {
      const events = (
        await API.graphql(
          graphqlOperation(eventsbyCycle, {
            cycleId: secondScreenHook[0].cycleId,
          })
        )
      ).data.eventsbyCycle.items;

      console.log("events", events);
      setEvents(events);
    }
  }, []);

  useEffect(() => {}, [events]);

  useEffect(async () => {
    if (selectionModel) {
      const events = await Promise.all(
        selectionModel.map(async (id) => {
          const res = await API.graphql(
            graphqlOperation(getEvent, {
              id,
            })
          );
          return await res.data.getEvent;
        })
      );
      setEvents(events);
      setEventsCallback(events);
    }
  }, [selectionModel]);

  useEffect(() => {
    setFormData({ ...formData, documentHtml: newDocumentHtml });
  }, [newDocumentHtml]);

  var availablePlaces = events.map((event) => {
    let attendingNb = 0;

    if (typeof event.attendingNb === "number") attendingNb = event.attendingNb;

    return event.nbMax - attendingNb;
  });
  var availableDates = events.map((event) => event.date);
  var prices = events.map((event) => event.prix);

  var firstDate =
    availableDates.length &&
    getFullDate(Math.min.apply(Math, availableDates)).dateShort;
  var lastDate =
    availableDates.length &&
    getFullDate(Math.max.apply(Math, availableDates)).dateShort;
  var totalPrice = prices.reduce((a, b) => a + b, 0);

  var availableRoom = Math.min.apply(Math, availablePlaces);

  useEffect(async () => {
    if (!cycleRef || !cycleRef.current) {
      API.graphql(
        graphqlOperation(getCycle, { id: secondScreenHook[0].cycleId })
      ).then(async (res) => {
        const data = res.data.getCycle;
        setFormData({
          title: data.title,
          prix: data.prix,
          documentHtml: data.documentHtml,
        });
        const events = await API.graphql(
          graphqlOperation(eventsbyCycle, {
            cycleId: secondScreenHook[0].cycleId,
          })
        ).data.eventsbyCycle.items;
        setEvents(events);
      });
      return;
    }
    setFormData({
      title: secondScreenHook[0].cycleId ? cycleRef.current.title : "",
      prix: totalPrice,
      documentHtml: secondScreenHook[0].cycleId
        ? cycleRef.current.documentHtml
        : "",
      prix: secondScreenHook[0].cycleId ? cycleRef.current.prix : 0,
    });
    if (secondScreenHook[0].cycleId) {
      availableRoom -= cycleRef.current.attendingNb;
    }
  }, [secondScreenHook[0]]);

  const getModalStoreSignature = (formData) => {
    const testModalStore = {
      title: formData?.title,
      prix: formData?.prix,
      documentHtml: formData?.documentHtml,
      imageHash: formData?.imageStore?.hash,
    };
    return hash(testModalStore);
  };

  useEffect(() => {
    if (
      events.length === 0 ||
      availableRoom === 0 ||
      formData?.title?.length === 0
    )
      setShowButton(false);
  }, [getModalStoreSignature(formData)]);

  const getValue = (key) => {
    if (key === "checked") return formData ? formData[key] : true;

    return formData ? formData[key] : nullConference()[key];
  };

  const handleChange = (key) => {
    return (e) => {
      let value = e.target.value;

      if (key === "checked") {
        setFormData({ ...formData, checked: !getValue("checked") });
      }

      if (key === "prix") {
        if ((value > 0 && value < 2) || value < 0) {
          if (value < 0) value = 0;
          setShowButton(false);
          setPrixError(true);
          setFormData({ ...formData, prix: parseInt(value) });
        } else {
          let newValue = value;
          if (value?.length === 0) newValue = 0;

          setFormData({ ...formData, prix: parseInt(newValue) });
          setPrixError(false);
          if (!(nbMaxError || titleError)) setShowButton(true);
        }
      }
      if (key === "title") {
        if (value === "") {
          setTitleError(true);
        } else {
          setTitleError(false);

          if (!(prixError || nbMaxError)) setShowButton(true);
        }
        setFormData({
          ...formData,
          title: value,
        });
      }
    };
  };

  const marginText = "7px";

  function FormRow({ label, value }) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyItems="stretch"
        padding="2px 0 2px 0"
        width="100%"
      >
        <Box width="75%">
          <Typography className={classes.info}>{label}</Typography>
        </Box>
        <Box width="20%" paddingLeft="5px">
          <Typography className={classes.info}>{value}</Typography>
        </Box>
      </Box>
    );
  }

  const AlertMessage = () => {
    return (
      <Box marginTop="60px" marginBottom="60px">
        <Typography
          className={classes.info}
          style={{ padding: "10px", backgroundColor: red[100] }}
        >
          Vous devez selectionner les événements que vous voulez ajouter au
          nouveau cycle.
        </Typography>
      </Box>
    );
  };

  const [spinner, setSpinner] = useState(false);

  return (
    <Box height="70vh" width="120vh" margin="10px 20px 10px 20px">
      {secondScreenHook[0] && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center "
          height="100%"
          width="100%"
        >
          <Box
            flex={2}
            padding="00px 10px 00px 10px"
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            justifyContent="space-between"
            justifyItems="stretch"
          >
            <Box
              padding="20px 10px 20px 10px"
              border={`1px solid ${grey[200]}`}
            >
              <Box paddingBottom="10px">
                <Typography className={classes.info}>Récapitulatif</Typography>
              </Box>

              <FormRow
                label="Nombre total d'événements:"
                value={events.length}
              />
              {events.length > 0 && (
                <FormRow
                  label="Nombre de places disponibles:"
                  value={availableRoom}
                />
              )}
              {events.length > 0 && (
                <FormRow label="Début:" value={firstDate} />
              )}
              {events.length > 0 && <FormRow label="Fin:" value={lastDate} />}
              {events.length > 0 && (
                <FormRow label="Valeur totale: " value={`${totalPrice}€`} />
              )}
              {events.length === 0 && <AlertMessage />}
            </Box>

            {events.length > 0 && (
              <Box
                height="300px"
                padding="20px 10px 20px 10px"
                border={`1px solid ${grey[200]}`}
              >
                <Box paddingBottom="10px">
                  <Typography className={classes.info}>
                    Informations supplémentaires
                  </Typography>
                </Box>
                <Box marginTop="10px">
                  <Box>
                    <Typography className={classes.info}>Image</Typography>
                  </Box>

                  <Box display="flex" flexDirection="row">
                    <ImageUpload
                      setSpinner={setSpinner}
                      label="+ photos"
                      modalStore={formData}
                      setModalStore={setFormData}
                    />
                    {formData?.imageStore?.original && (
                      <Box width="50px" height="50px">
                        <img
                          src={formData?.imageStore?.original}
                          alt=""
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                <form className={classes.root} noValidate autoComplete="off">
                  <Box className={classes.textFieldContainer}>
                    <TextField
                      error={titleError}
                      id="title"
                      value={formData && formData.title ? formData.title : ""}
                      className={classes.textField}
                      fullWidth
                      required
                      label="Titre"
                      variant="filled"
                      onChange={handleChange("title")}
                      InputProps={{
                        shrink: "true",
                        classes: {
                          root: classes.textFieldRoot,
                          focused: classes.textFieldRootAlt,
                          input: classes.textFieldRootAlt2,
                        },
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textFieldLabel },
                      }}
                    />
                  </Box>
                  <Box className={classes.textFieldContainer}>
                    <TextField
                      id="prix"
                      variant="filled"
                      error={prixError}
                      className={classes.textField}
                      style={{ width: "100px" }}
                      type="number"
                      required
                      value={formData ? formData.prix : 0}
                      onChange={handleChange("prix")}
                      label="Prix (€)"
                      InputProps={{
                        shrink: "true",
                        classes: {
                          root: classes.textFieldRoot,
                          focused: classes.textFieldRootAlt,
                          input: classes.textFieldRootAlt2,
                        },
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textFieldLabel },
                      }}
                    />
                  </Box>
                </form>
              </Box>
            )}
          </Box>
          <Box flex={3} height="100%" width="100%" paddingLeft="10px">
            <Box height="100%" width="100%">
              <TextEditor
                setContent={setNewDocumentHtml}
                content={formData?.documentHtml}
                setNewDocumentHtml={setNewDocumentHtml}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CycleForm;

// const useStylesOld = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//             width: '100%',
//             height: "100%",
//             display: "flex",
//             padding: '20px 3px 20px 0px',
//             flexDirection: 'column'
//         },

//     },
//     container: {
//         display: 'grid',
//         gridTemplateColumns: 'repeat(12, 1fr)',
//         gridGap: theme.spacing(3),
//     },
//     paper: {
//         padding: theme.spacing(1),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         whiteSpace: 'nowrap',
//         marginBottom: theme.spacing(1),
//     },
//     paperLeft: {
//         width: '500px'

//     },
//     paperRigjt: {
//         width: '00px'

//     },
//     info: {
//         fontSize: '0.8rem',
//         color: `${grey[700]}`,
//         fontWeight: 500
//     },
//     infoContainer: {
//         padding: "10px 0 10px 0"
//     },
//     textField: {
//         width: '100%'
//     },
//     textFieldContainer: {

//     }
// }), { index: 1 });
