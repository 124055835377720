/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompanyAccount = /* GraphQL */ `
  query GetCompanyAccount($id: ID!) {
    getCompanyAccount(id: $id) {
      id
      fullName
      nbMaxMembers
      category
      memberShips {
        nextToken
        __typename
      }
      documentHtml
      web
      state
      description
      hashList
      imageId
      type
      lemonwayId
      promotionProgram
      abonnement
      imageExt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyAccounts = /* GraphQL */ `
  query ListCompanyAccounts(
    $filter: ModelCompanyAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        nbMaxMembers
        category
        documentHtml
        web
        state
        description
        hashList
        imageId
        type
        lemonwayId
        promotionProgram
        abonnement
        imageExt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      category
      codeChain
      inceptionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $filter: ModelpromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        codeChain
        inceptionDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalon = /* GraphQL */ `
  query GetSalon($id: ID!) {
    getSalon(id: $id) {
      id
      formateurId
      organisateurId
      title
      subtitle
      htmlDocument
      image
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalons = /* GraphQL */ `
  query ListSalons(
    $filter: ModelSalonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formateurId
        organisateurId
        title
        subtitle
        htmlDocument
        image
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMemberShip = /* GraphQL */ `
  query GetMemberShip($id: ID!) {
    getMemberShip(id: $id) {
      id
      formateurId
      companyAccountId
      administratorPrivileges
      accountantPrivileges
      editorPrivileges
      communicationPrivileges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMemberShips = /* GraphQL */ `
  query ListMemberShips(
    $filter: ModelmemberShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formateurId
        companyAccountId
        administratorPrivileges
        accountantPrivileges
        editorPrivileges
        communicationPrivileges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      from
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        from
        email
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormateur = /* GraphQL */ `
  query GetFormateur($id: ID!) {
    getFormateur(id: $id) {
      id
      documentHtml
      cognitoId
      prenom
      isFollowed {
        nextToken
        __typename
      }
      nom
      url
      state
      imageId
      pixelCropWidth
      pixelCropHeight
      pixelCropX
      pixelCropY
      hasPhoto
      photoHash
      note
      nbAvis
      youtube
      linkedin
      intro
      markdown
      hashList
      attributes {
        nextToken
        __typename
      }
      cycles {
        nextToken
        __typename
      }
      publications {
        nextToken
        __typename
      }
      publicationList
      documentPublications {
        nextToken
        __typename
      }
      mailingList {
        nextToken
        __typename
      }
      specialites
      sanboxLemonwayId
      lemonwayId
      companyAccountId
      promotionProgram
      abonnement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFormateurs = /* GraphQL */ `
  query ListFormateurs(
    $filter: ModelFormateurFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormateurs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        documentHtml
        cognitoId
        prenom
        nom
        url
        state
        imageId
        pixelCropWidth
        pixelCropHeight
        pixelCropX
        pixelCropY
        hasPhoto
        photoHash
        note
        nbAvis
        youtube
        linkedin
        intro
        markdown
        hashList
        publicationList
        specialites
        sanboxLemonwayId
        lemonwayId
        companyAccountId
        promotionProgram
        abonnement
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClientAttribute = /* GraphQL */ `
  query GetClientAttribute($id: ID!) {
    getClientAttribute(id: $id) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClientAttributes = /* GraphQL */ `
  query ListClientAttributes(
    $filter: ModelClientAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientAttributes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        key
        value
        additionalValue
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGatewayEvents = /* GraphQL */ `
  query GetGatewayEvents($id: ID!) {
    getGatewayEvents(id: $id) {
      id
      channel
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGatewayEventss = /* GraphQL */ `
  query ListGatewayEventss(
    $filter: ModelGatewayEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGatewayEventss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channel
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPresentCards = /* GraphQL */ `
  query GetPresentCards($id: ID!) {
    getPresentCards(id: $id) {
      id
      clientReceiverId
      clientReceiverEmail
      clientDonorId
      amount
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPresentCardss = /* GraphQL */ `
  query ListPresentCardss(
    $filter: ModelPresentCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPresentCardss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientReceiverId
        clientReceiverEmail
        clientDonorId
        amount
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormateurAttribute = /* GraphQL */ `
  query GetFormateurAttribute($id: ID!) {
    getFormateurAttribute(id: $id) {
      id
      clientId
      key
      value
      additionalValue
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFormateurAttributes = /* GraphQL */ `
  query ListFormateurAttributes(
    $filter: ModelFormateurAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormateurAttributes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        key
        value
        additionalValue
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCredit = /* GraphQL */ `
  query GetCredit($id: ID!) {
    getCredit(id: $id) {
      id
      clientId
      category
      amount
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCredits = /* GraphQL */ `
  query ListCredits(
    $filter: ModelCreditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCredits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        category
        amount
        startDate
        endDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      cognitoId
      prenom
      follows {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      credits {
        nextToken
        __typename
      }
      newsletterConsentDate
      suscribeStatus
      CGUVersion
      nom
      sanboxLemonwayId
      lemonwayId
      TVAid
      emailId
      email {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      attributes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        prenom
        newsletterConsentDate
        suscribeStatus
        CGUVersion
        nom
        sanboxLemonwayId
        lemonwayId
        TVAid
        emailId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailTag = /* GraphQL */ `
  query GetEmailTag($id: ID!) {
    getEmailTag(id: $id) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailTags = /* GraphQL */ `
  query ListEmailTags(
    $filter: ModelEmailTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmail = /* GraphQL */ `
  query GetEmail($id: ID!) {
    getEmail(id: $id) {
      id
      clientId
      email
      formateurOwnerId
      pekoiaClient
      source
      suscribedStatus
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmails = /* GraphQL */ `
  query ListEmails(
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      eventId
      key
      question
      grade
      comment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        key
        question
        grade
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublication = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      id
      index
      formateurId
      organisateurId
      salonId
      type
      imageId
      imageExt
      imageTitle
      url
      ogTitle
      ogImage
      category
      activityType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPublications = /* GraphQL */ `
  query ListPublications(
    $filter: ModelPublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        formateurId
        organisateurId
        salonId
        type
        imageId
        imageExt
        imageTitle
        url
        ogTitle
        ogImage
        category
        activityType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventsLogs = /* GraphQL */ `
  query GetEventsLogs($id: ID!) {
    getEventsLogs(id: $id) {
      id
      type
      value
      key
      handled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventsLogss = /* GraphQL */ `
  query ListEventsLogss(
    $filter: ModelEventsLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsLogss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        value
        key
        handled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      modelId
      isLinkedToModel
      formateurId
      cycleId
      isLiked {
        nextToken
        __typename
      }
      organisateurId
      documentId
      state
      title1
      title2
      langue
      categories
      categoriesCode
      niveau
      prix
      date
      duree
      duration
      linkUrl
      type
      urls
      format
      nbMax
      nbReserved
      attendingNb
      outil
      outilUrl
      tags
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      eTagProcess
      htmlText {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
        __typename
      }
      address
      lat
      lng
      password
      liveStatus
      replayCategory
      replayWeTransferUrl
      replayKey
      replayStatus
      futurReplay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        modelId
        isLinkedToModel
        formateurId
        cycleId
        organisateurId
        documentId
        state
        title1
        title2
        langue
        categories
        categoriesCode
        niveau
        prix
        date
        duree
        duration
        linkUrl
        type
        urls
        format
        nbMax
        nbReserved
        attendingNb
        outil
        outilUrl
        tags
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        eTagProcess
        address
        lat
        lng
        password
        liveStatus
        replayCategory
        replayWeTransferUrl
        replayKey
        replayStatus
        futurReplay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCycle = /* GraphQL */ `
  query GetCycle($id: ID!) {
    getCycle(id: $id) {
      id
      formateurId
      organisateurId
      title
      status
      replayStatus
      documentHtml
      imageKey
      imageETagKeyRef
      imageETag
      imageETag300
      imageETag500
      imageETag800
      imageETagOgImage
      nbMax
      attendingNb
      prix
      events {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCycles = /* GraphQL */ `
  query ListCycles(
    $filter: ModelCycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCycles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formateurId
        organisateurId
        title
        status
        replayStatus
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        nbMax
        attendingNb
        prix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStates = /* GraphQL */ `
  query GetStates($id: ID!) {
    getStates(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStatess = /* GraphQL */ `
  query ListStatess(
    $filter: ModelStatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFollows = /* GraphQL */ `
  query GetFollows($id: ID!) {
    getFollows(id: $id) {
      id
      clientId
      formateurId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFollowss = /* GraphQL */ `
  query ListFollowss(
    $filter: ModelFollowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        formateurId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLikes = /* GraphQL */ `
  query GetLikes($id: ID!) {
    getLikes(id: $id) {
      id
      clientId
      eventId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLikess = /* GraphQL */ `
  query ListLikess(
    $filter: ModelLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      title
      clientId
      eventId
      eventImageKey
      eventImageETag300
      isReplay
      date
      eventDate
      expiryDate
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        clientId
        eventId
        eventImageKey
        eventImageETag300
        isReplay
        date
        eventDate
        expiryDate
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganisateur = /* GraphQL */ `
  query GetOrganisateur($id: ID!) {
    getOrganisateur(id: $id) {
      id
      nom
      publications {
        nextToken
        __typename
      }
      note
      nbAvis
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganisateurs = /* GraphQL */ `
  query ListOrganisateurs(
    $filter: ModelOrganisateurFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisateurs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nom
        note
        nbAvis
        url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProcessController = /* GraphQL */ `
  query GetProcessController($id: ID!) {
    getProcessController(id: $id) {
      id
      name
      jsonState
      lastUpdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProcessControllers = /* GraphQL */ `
  query ListProcessControllers(
    $filter: ModelProcessControllerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcessControllers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        jsonState
        lastUpdate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentHtml = /* GraphQL */ `
  query GetDocumentHtml($id: ID!) {
    getDocumentHtml(id: $id) {
      id
      modelId
      events {
        nextToken
        __typename
      }
      content
      HtmlCategory
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocumentHtmls = /* GraphQL */ `
  query ListDocumentHtmls(
    $filter: ModelDocumentHtmlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentHtmls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        modelId
        content
        HtmlCategory
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      eventId
      cycleId
      giftCard
      receiverEmail
      clientId
      sandboxId
      lemonwayId
      lemonwayCardId
      lemonwayExpiration
      lemonwayMaskedNumber
      lemonwayType
      status
      promoStatus
      scheduledTask
      scheduledDate
      createDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        cycleId
        giftCard
        receiverEmail
        clientId
        sandboxId
        lemonwayId
        lemonwayCardId
        lemonwayExpiration
        lemonwayMaskedNumber
        lemonwayType
        status
        promoStatus
        scheduledTask
        scheduledDate
        createDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageContent = /* GraphQL */ `
  query GetMessageContent($id: ID!) {
    getMessageContent(id: $id) {
      id
      category
      content
      hash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessageContents = /* GraphQL */ `
  query ListMessageContents(
    $filter: ModelMessageContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        content
        hash
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      category
      status
      date
      contextKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        status
        date
        contextKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSendMessage = /* GraphQL */ `
  query GetSendMessage($id: ID!) {
    getSendMessage(id: $id) {
      id
      messageContentId
      destFormateurId
      destFormateurTag
      destClientId
      destClientTag
      priority
      expiry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSendMessages = /* GraphQL */ `
  query ListSendMessages(
    $filter: ModelSendMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSendMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageContentId
        destFormateurId
        destFormateurTag
        destClientId
        destClientTag
        priority
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const memberShipByFormateurId = /* GraphQL */ `
  query MemberShipByFormateurId(
    $formateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelmemberShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberShipByFormateurId(
      formateurId: $formateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formateurId
        companyAccountId
        administratorPrivileges
        accountantPrivileges
        editorPrivileges
        communicationPrivileges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formateurByCognitoId = /* GraphQL */ `
  query FormateurByCognitoId(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelFormateurFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formateurByCognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentHtml
        cognitoId
        prenom
        nom
        url
        state
        imageId
        pixelCropWidth
        pixelCropHeight
        pixelCropX
        pixelCropY
        hasPhoto
        photoHash
        note
        nbAvis
        youtube
        linkedin
        intro
        markdown
        hashList
        publicationList
        specialites
        sanboxLemonwayId
        lemonwayId
        companyAccountId
        promotionProgram
        abonnement
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formateurbyCompanyAccount = /* GraphQL */ `
  query FormateurbyCompanyAccount(
    $companyAccountId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFormateurFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formateurbyCompanyAccount(
      companyAccountId: $companyAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentHtml
        cognitoId
        prenom
        nom
        url
        state
        imageId
        pixelCropWidth
        pixelCropHeight
        pixelCropX
        pixelCropY
        hasPhoto
        photoHash
        note
        nbAvis
        youtube
        linkedin
        intro
        markdown
        hashList
        publicationList
        specialites
        sanboxLemonwayId
        lemonwayId
        companyAccountId
        promotionProgram
        abonnement
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attributesbyClientId = /* GraphQL */ `
  query AttributesbyClientId(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClientAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attributesbyClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        key
        value
        additionalValue
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gatewayEventByChannel = /* GraphQL */ `
  query GatewayEventByChannel(
    $channel: String
    $sortDirection: ModelSortDirection
    $filter: ModelGatewayEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gatewayEventByChannel(
      channel: $channel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channel
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attributesbyDonorId = /* GraphQL */ `
  query AttributesbyDonorId(
    $clientReceiverId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPresentCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attributesbyDonorId(
      clientReceiverId: $clientReceiverId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientReceiverId
        clientReceiverEmail
        clientDonorId
        amount
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attributesbyReceiverId = /* GraphQL */ `
  query AttributesbyReceiverId(
    $clientDonorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPresentCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attributesbyReceiverId(
      clientDonorId: $clientDonorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientReceiverId
        clientReceiverEmail
        clientDonorId
        amount
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attributesbyFormateurId = /* GraphQL */ `
  query AttributesbyFormateurId(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFormateurAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attributesbyFormateurId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        key
        value
        additionalValue
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const creditsbyClient = /* GraphQL */ `
  query CreditsbyClient(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCreditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditsbyClient(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        category
        amount
        startDate
        endDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const clientByCognitoId = /* GraphQL */ `
  query ClientByCognitoId(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByCognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        prenom
        newsletterConsentDate
        suscribeStatus
        CGUVersion
        nom
        sanboxLemonwayId
        lemonwayId
        TVAid
        emailId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tagsByEmailId = /* GraphQL */ `
  query TagsByEmailId(
    $id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmailTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByEmailId(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const emailByEmail = /* GraphQL */ `
  query EmailByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const emailsByformateurId = /* GraphQL */ `
  query EmailsByformateurId(
    $formateurOwnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailsByformateurId(
      formateurOwnerId: $formateurOwnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        email
        formateurOwnerId
        pekoiaClient
        source
        suscribedStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyByEventId = /* GraphQL */ `
  query SurveyByEventId(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        key
        question
        grade
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicationsbyFormateur = /* GraphQL */ `
  query PublicationsbyFormateur(
    $formateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicationsbyFormateur(
      formateurId: $formateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        formateurId
        organisateurId
        salonId
        type
        imageId
        imageExt
        imageTitle
        url
        ogTitle
        ogImage
        category
        activityType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsbyFormateur = /* GraphQL */ `
  query EventsbyFormateur(
    $formateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsbyFormateur(
      formateurId: $formateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelId
        isLinkedToModel
        formateurId
        cycleId
        organisateurId
        documentId
        state
        title1
        title2
        langue
        categories
        categoriesCode
        niveau
        prix
        date
        duree
        duration
        linkUrl
        type
        urls
        format
        nbMax
        nbReserved
        attendingNb
        outil
        outilUrl
        tags
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        eTagProcess
        address
        lat
        lng
        password
        liveStatus
        replayCategory
        replayWeTransferUrl
        replayKey
        replayStatus
        futurReplay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsbyCycle = /* GraphQL */ `
  query EventsbyCycle(
    $cycleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsbyCycle(
      cycleId: $cycleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelId
        isLinkedToModel
        formateurId
        cycleId
        organisateurId
        documentId
        state
        title1
        title2
        langue
        categories
        categoriesCode
        niveau
        prix
        date
        duree
        duration
        linkUrl
        type
        urls
        format
        nbMax
        nbReserved
        attendingNb
        outil
        outilUrl
        tags
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        eTagProcess
        address
        lat
        lng
        password
        liveStatus
        replayCategory
        replayWeTransferUrl
        replayKey
        replayStatus
        futurReplay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsbyOrganisateur = /* GraphQL */ `
  query EventsbyOrganisateur(
    $organisateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsbyOrganisateur(
      organisateurId: $organisateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelId
        isLinkedToModel
        formateurId
        cycleId
        organisateurId
        documentId
        state
        title1
        title2
        langue
        categories
        categoriesCode
        niveau
        prix
        date
        duree
        duration
        linkUrl
        type
        urls
        format
        nbMax
        nbReserved
        attendingNb
        outil
        outilUrl
        tags
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        eTagProcess
        address
        lat
        lng
        password
        liveStatus
        replayCategory
        replayWeTransferUrl
        replayKey
        replayStatus
        futurReplay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsbyDocument = /* GraphQL */ `
  query EventsbyDocument(
    $documentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsbyDocument(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelId
        isLinkedToModel
        formateurId
        cycleId
        organisateurId
        documentId
        state
        title1
        title2
        langue
        categories
        categoriesCode
        niveau
        prix
        date
        duree
        duration
        linkUrl
        type
        urls
        format
        nbMax
        nbReserved
        attendingNb
        outil
        outilUrl
        tags
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        eTagProcess
        address
        lat
        lng
        password
        liveStatus
        replayCategory
        replayWeTransferUrl
        replayKey
        replayStatus
        futurReplay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cyclesbyFormateur = /* GraphQL */ `
  query CyclesbyFormateur(
    $formateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cyclesbyFormateur(
      formateurId: $formateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formateurId
        organisateurId
        title
        status
        replayStatus
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        nbMax
        attendingNb
        prix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cyclesbyOrganisateur = /* GraphQL */ `
  query CyclesbyOrganisateur(
    $organisateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cyclesbyOrganisateur(
      organisateurId: $organisateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formateurId
        organisateurId
        title
        status
        replayStatus
        documentHtml
        imageKey
        imageETagKeyRef
        imageETag
        imageETag300
        imageETag500
        imageETag800
        imageETagOgImage
        nbMax
        attendingNb
        prix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const clientsByFollow = /* GraphQL */ `
  query ClientsByFollow(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFollowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByFollow(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        formateurId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formateursByFollow = /* GraphQL */ `
  query FormateursByFollow(
    $formateurId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFollowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formateursByFollow(
      formateurId: $formateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        formateurId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const clientsByLikes = /* GraphQL */ `
  query ClientsByLikes(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByLikes(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formateursByLikes = /* GraphQL */ `
  query FormateursByLikes(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formateursByLikes(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByClientId = /* GraphQL */ `
  query NotificationsByClientId(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        clientId
        eventId
        eventImageKey
        eventImageETag300
        isReplay
        date
        eventDate
        expiryDate
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByEventId = /* GraphQL */ `
  query NotificationsByEventId(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        clientId
        eventId
        eventImageKey
        eventImageETag300
        isReplay
        date
        eventDate
        expiryDate
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByClientId = /* GraphQL */ `
  query TransactionsByClientId(
    $clientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        cycleId
        giftCard
        receiverEmail
        clientId
        sandboxId
        lemonwayId
        lemonwayCardId
        lemonwayExpiration
        lemonwayMaskedNumber
        lemonwayType
        status
        promoStatus
        scheduledTask
        scheduledDate
        createDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByStatus = /* GraphQL */ `
  query TransactionsByStatus(
    $status: TransactionStatus
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        cycleId
        giftCard
        receiverEmail
        clientId
        sandboxId
        lemonwayId
        lemonwayCardId
        lemonwayExpiration
        lemonwayMaskedNumber
        lemonwayType
        status
        promoStatus
        scheduledTask
        scheduledDate
        createDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByEventId = /* GraphQL */ `
  query TransactionsByEventId(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        cycleId
        giftCard
        receiverEmail
        clientId
        sandboxId
        lemonwayId
        lemonwayCardId
        lemonwayExpiration
        lemonwayMaskedNumber
        lemonwayType
        status
        promoStatus
        scheduledTask
        scheduledDate
        createDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByCycleId = /* GraphQL */ `
  query TransactionsByCycleId(
    $cycleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCycleId(
      cycleId: $cycleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        cycleId
        giftCard
        receiverEmail
        clientId
        sandboxId
        lemonwayId
        lemonwayCardId
        lemonwayExpiration
        lemonwayMaskedNumber
        lemonwayType
        status
        promoStatus
        scheduledTask
        scheduledDate
        createDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sendMessageByClientId = /* GraphQL */ `
  query SendMessageByClientId(
    $destClientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSendMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sendMessageByClientId(
      destClientId: $destClientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageContentId
        destFormateurId
        destFormateurTag
        destClientId
        destClientTag
        priority
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sendMessageByFormateurId = /* GraphQL */ `
  query SendMessageByFormateurId(
    $destFormateurId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSendMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sendMessageByFormateurId(
      destFormateurId: $destFormateurId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageContentId
        destFormateurId
        destFormateurTag
        destClientId
        destClientTag
        priority
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
