import React, { useState, useEffect, forwardRef, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import TextFieldFake from "@mui/material/TextField";

import { CircularProgress, alpha } from "@mui/material";
import Box from "@mui/material/Box";
import { langues } from "./general";
import Typography from "@mui/material/Typography";
import { getDate, nullConference } from "./general";
import {
  eventCategories,
  choix,
  outilsChoix,
  niveauxChoix,
} from "./helpers/events";
import { green, grey } from "@mui/material/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { cleanHtml } from "../components/SVGIcons/helpers";
import ImageUpload from "./uploadImage-3";
import TextEditor from "./helpers/sunTextEditor";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import useStylesOld from "./style/textFields";
import { API, graphqlOperation } from "aws-amplify";
import { getEvent, listEvents, listFormateurs } from "../graphql/queries";
import theme from "../AppStyle";
import { getImage } from "./conferencePrepa";

registerLocale("fr", fr);

//const TextFieldFake = () => <Box></Box>

const nbMaxCategories = 3;
const MAX_ATELIER = 50;

// export const ModalModifyConference = (props) => {
//     return null
// }

const EventForm = (props) => {
  const {
    isClone,
    secondScreenHook,
    formData,
    setFormData,
    setShowButton,
    companyId,
  } = props;
  const [newDocumentHtml, setNewDocumentHtml] = useState(null);
  const [formateursPrivate, setFormateursPrivate] = useState([]);
  const modalStore = formData;
  // TO BE MODIFED BACK
  // TOTO
  const setModalStore = setFormData;

  const classes = useStylesOld(props);
  const [prixError, setPrixError] = useState(false);
  const [nbMaxError, setNbMaxError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const [event, setEvent] = useState(
    useSelector((state) => state.eventsReducer).data.find(
      (event) => event.id === secondScreenHook[0]?.eventId
    )
  );
  useEffect(async () => {
    if (companyId) {
      const event = (
        await API.graphql(
          graphqlOperation(getEvent, {
            id: secondScreenHook[0]?.eventId,
          })
        )
      ).data.getEvent;

      const base64Image = await getImage(
        { id: event.formateurId },
        event.imageKey,
        event.imageETagOgImage
      );
      setEvent({ ...event, base64Image });
    }
  }, []);
  // const event = useSelector(state => state.eventsReducer).data.find(event => event.id === secondScreenHook[0]?.eventId)
  const test = useSelector((state) => state.eventsReducer);
  const eventRef = useRef(event);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      style={{
        fontSize: "1rem",
        paddingBottom: "3px",
        borderBottom: "1px solid",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </div>
  ));

  let nbAttending = 0;

  if (typeof event?.attendingNb === "number") nbAttending = event?.attendingNb;

  const modifiable =
    secondScreenHook[0]?.state === "clone" ||
    nbAttending === 0 ||
    event.state == "CANCELED";

  useEffect(() => {
    if (companyId) {
      if (secondScreenHook[0]?.state === "modify") {
        API.graphql(
          graphqlOperation(listEvents, {
            filter: { organisateurId: { eq: companyId } },
          })
        ).then((res) => {
          setEvent(
            res.data.listEvents.items.find(
              (event) => event.id === secondScreenHook[0]?.eventId
            )
          );
        });
      }
      API.graphql(
        graphqlOperation(listFormateurs, {
          filter: { companyAccountId: { eq: companyId } },
        })
      ).then((res) => {
        setFormateursPrivate(res.data.listFormateurs.items);
      });
    }
  }, []);

  useEffect(() => {
    const localEvent = event;

    const getValue = (key) => {
      if (key == "date")
        return localEvent?.date
          ? getDate(localEvent.date)
          : nullConference().date;
      if (key == "modifiable") return modifiable;
      if (key == "checked") return localEvent ? localEvent.date == 0 : false;
      if (key == "address") return null;
      if (key == "documentHtml") return cleanHtml(localEvent?.documentHtml);

      // GENERAL CASE
      return localEvent ? localEvent[key] : nullConference()[key];
    };

    const mainKeyList = [
      "title1",
      "title2",
      "format",
      "duree",
      "outil",
      "nbMax",
      "prix",
      "outilUrl",
      "documentHtml",
      "checked",
      "formateurId",
      "langue",
      "niveau",
      "categories",
      "date",
      "modifiable",
      "checked",
      "address",
      "documentHtml",
      "futurReplay",
    ];
    const newValue = {};
    mainKeyList.forEach((key) => (newValue[key] = getValue(key)));
    setModalStore(newValue);
  }, [event]);

  useEffect(() => {
    setFormData({ ...formData, documentHtml: newDocumentHtml });
  }, [newDocumentHtml]);

  useEffect(() => {
    const localEvent = eventRef.current;

    const getValue = (key) => {
      if (key == "date")
        return localEvent?.date
          ? getDate(localEvent.date)
          : nullConference().date;
      if (key == "modifiable") return modifiable;
      if (key == "checked") return localEvent ? localEvent.date == 0 : false;
      if (key == "address") return null;
      if (key == "documentHtml") return cleanHtml(localEvent?.documentHtml);

      // GENERAL CASE
      return localEvent ? localEvent[key] : nullConference()[key];
    };

    const mainKeyList = [
      "title1",
      "title2",
      "format",
      "duree",
      "outil",
      "nbMax",
      "prix",
      "outilUrl",
      "documentHtml",
      "checked",
      "formateurId",
      "langue",
      "niveau",
      "categories",
      "date",
      "modifiable",
      "checked",
      "address",
      "documentHtml",
      "futurReplay",
    ];
    const newValue = {};
    mainKeyList.forEach((key) => (newValue[key] = getValue(key)));
    setModalStore(newValue);

    // }, [secondScreenHook[0]])
  }, []);

  const getValue = (key) => {
    const event = eventRef.current;

    if (key === "checked") {
      const eventValue = event && event["date"] == 0;
      const modalValue = modalStore && modalStore[key];
      const foundInModalStore = modalStore;
      return foundInModalStore ? modalValue : eventValue;
    }
    if (key === "futurReplay") {
      const eventValue =
        event && event[key] ? event[key] : nullConference()[key];
      const modalValue = modalStore && modalStore[key];
      const foundInModalStore = modalStore;
      return foundInModalStore ? modalValue : eventValue;
    }

    const eventValue = event && event[key] ? event[key] : nullConference()[key];
    const modalValue =
      modalStore && modalStore[key] ? modalStore[key] : nullConference()[key];
    const foundInModalStore = modalStore && modalStore[key];
    return foundInModalStore ? modalValue : eventValue;
  };

  useEffect(() => {
    if (prixError || nbMaxError || titleError) setShowButton(false);
    else {
      setShowButton(true);
    }
  }, [prixError, nbMaxError, titleError]);

  const [spinner, setSpinner] = useState(false);

  const handleDateChange = (newDate) => {
    if (modifiable) {
      setModalStore({
        ...modalStore,
        date: newDate,
      });
    }
  };

  const handleChange = (key) => {
    return (e) => {
      let value = e.target.value;
      if (key === "checked" && modifiable) {
        setModalStore({ ...modalStore, checked: !getValue("checked") });
        return;
      }
      if (key === "futurReplay") {
        setModalStore({ ...modalStore, futurReplay: !getValue("futurReplay") });
      }
      if (key === "prix") {
        if (value === "") value = 0;
        if ((value > 0 && value < 2) || value < 0) {
          if (value < 0) value = 0;
          setShowButton(false);
          setPrixError(true);
        } else {
          setPrixError(false);
          if (!(nbMaxError || titleError)) setShowButton(true);
        }
      }
      if (key === "duree") {
        if (value < 5) value = 5;
        if (value > 120) value = 120;
      }
      if (key === "nbMax") {
        if (value > MAX_ATELIER && modalStore.format === "CLASSE") {
          setModalStore({
            ...modalStore,
            nbMax: MAX_ATELIER,
          });
        }
        if (value < 0) {
          if (value < 0) value = 0;
          setNbMaxError(true);
        } else {
          setNbMaxError(false);
          if (!(prixError || titleError)) setShowButton(true);
        }
      }
      if (key === "title1") {
        if (value === "") {
          setTitleError(true);
        } else {
          setTitleError(false);
          if (!(prixError || nbMaxError)) setShowButton(true);
        }
        setModalStore({
          ...modalStore,
          title1: value,
        });
        return;
      }
      if (key != "checked" && key != "category" && key != "futurReplay")
        if (modifiable)
          setModalStore({
            ...modalStore,
            [key]: value,
          });
      if (key === "category") {
        const categories = modalStore.categories
          ? modalStore.categories.split("|")
          : [];
        if (
          value != nullConference.category &&
          categories.length < nbMaxCategories
        ) {
          const newCategories = categories.concat(value);
          setModalStore({ ...modalStore, categories: newCategories.join("|") });
        }
      }
      if (key === "format") {
        if (value === "CLASSE" && modalStore.nbMax > MAX_ATELIER)
          setModalStore({
            ...modalStore,
            nbMax: MAX_ATELIER,
            format: "CLASSE",
          });
      }
      if (key === "formateurId") {
        setModalStore({
          ...modalStore,
          formateurId: value,
        });
      }
    };
  };

  const marginText = "7px";
  const handleSelectionClick = (index) => () => {
    const previousCategories = modalStore.categories.split("|");
    previousCategories.splice(index, 1);
    setModalStore({ ...modalStore, categories: previousCategories.join("|") });
  };
  const textFieldStyle = { margin: marginText, backgroundColor: "white" };
  const labelClass = (key) => {
    if (key == "title1" && getValue("title1").length == 0)
      return classes.textFieldLabelError;
    if (key == "outilUrl" && getValue("outilUrl").length == 0)
      return classes.textFieldLabelError;
    if (key == "image" && (formData?.imageStore || event?.base64Image))
      return classes.imageContainer;
    if (key == "image" && formData) return classes.imageContainerError;
    return classes.textFieldLabel;
  };

  return (
    <Box width="100%" height="100%">
      {/* {<Box display="flex" flexDirection="row" justifyContent="center "> */}
      {secondScreenHook[0] && (
        <Box display="flex" flexDirection="row" justifyContent="center">
          <form className={classes.root} noValidate autoComplete="off">
            <div style={{ display: "flex", justifyItems: "end" }}>
              <div style={{ width: "100%" }}>
                <TextField
                  error={titleError}
                  id="title1"
                  value={getValue("title1")}
                  style={textFieldStyle}
                  InputProps={{
                    shrink: "true",
                    classes: {
                      root: classes.textFieldRoot,
                      focused: classes.textFieldRootAlt,
                      input: classes.textFieldRootAlt2,
                    },
                  }}
                  InputLabelProps={{ classes: { root: labelClass("title1") } }}
                  variant="filled"
                  fullWidth
                  required
                  label="Titre principal"
                  onChange={handleChange("title1")}
                />
                <TextField
                  id="title2"
                  value={getValue("title2")}
                  style={textFieldStyle}
                  variant="filled"
                  fullWidth
                  label="Titre secondaire"
                  InputProps={{
                    shrink: "true",
                    classes: {
                      root: classes.textFieldRoot,
                      focused: classes.textFieldRootAlt,
                      input: classes.textFieldRootAlt2,
                    },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.textFieldLabel },
                  }}
                  SelectProps={{ classes: { root: classes.selectProps } }}
                  onChange={handleChange("title2")}
                  className={classes.textField}
                />
                <Box display="flex" flexDirection="row">
                  <Box>
                    <TextField
                      id="category"
                      style={{ ...textFieldStyle, width: "200px" }}
                      select
                      variant="filled"
                      label="Nouveau thème"
                      InputProps={{
                        shrink: "true",
                        classes: {
                          root: classes.textFieldRoot,
                          focused: classes.textFieldRootAlt,
                          input: classes.textFieldRootAlt2,
                        },
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textFieldLabel },
                      }}
                      SelectProps={{ classes: { root: classes.selectProps } }}
                      value={getValue("category")}
                      onChange={handleChange("category")}
                    >
                      {eventCategories
                        .filter((category) => category != "")
                        .filter(
                          (category) =>
                            extractCategories(modalStore) === null ||
                            !extractCategories(modalStore).includes(category)
                        )
                        .map((category) => (
                          <MenuItem
                            key={category.toUpperCase()}
                            value={category}
                          >
                            {category}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Box>
                  {companyId ? (
                    <Box width="100%">
                      <TextFieldFake
                        id="formateur"
                        style={{
                          ...textFieldStyle,
                          width: "calc(100% - 20px)",
                          marginLeft: "20px",
                        }}
                        variant="filled"
                        select
                        label="Conférencier"
                        InputProps={{
                          shrink: "true",
                          classes: {
                            root: classes.textFieldRoot,
                            focused: classes.textFieldRootAlt,
                            input: classes.textFieldRootAlt2,
                          },
                        }}
                        InputLabelProps={{
                          classes: { root: classes.textFieldLabel },
                        }}
                        SelectProps={{ classes: { root: classes.selectProps } }}
                        value={getValue("formateurId")}
                        onChange={handleChange("formateurId")}
                      >
                        {formateursPrivate.map((option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {`${option.prenom} ${option.nom}`}
                            </MenuItem>
                          );
                        })}
                      </TextFieldFake>
                    </Box>
                  ) : null}
                </Box>
                <Box display="flex" flexDirection="row">
                  {/* <Box>
									<TextFieldFake
										id="format"
										style={{ ...textFieldStyle, width: '200px' }}
										variant="filled"

										select
										label="Format"
										InputProps={{ shrink: 'true', classes: { root: classes.textFieldRoot, focused: classes.textFieldRootAlt, input: classes.textFieldRootAlt2 } }}
										InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
										SelectProps={{ classes: { root: classes.selectProps } }}
										value={getValue("format")}
										onChange={handleChange("format")}
									>
										{choix.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextFieldFake>
								</Box> */}
                  {/* <Box width='100%'>
									<TextFieldFake
										variant="filled"
										id="niveau"
										style={{ ...textFieldStyle, marginLeft: '20px', width: 'calc(100% - 20px)' }}
										select
										label="Niveau"
										// fullWidth
										value={getValue("niveau")}
										InputProps={{ shrink: 'true', classes: { root: classes.textFieldRoot, focused: classes.textFieldRootAlt, input: classes.textFieldRootAlt2 } }}
										InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
										SelectProps={{ classes: { root: classes.selectProps } }}

										onChange={handleChange("niveau")}
									>
										{niveauxChoix.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextFieldFake>
								</Box> */}
                </Box>

                {!getValue("checked") && (
                  <Box display="flex" flexDirection="row">
                    {modalStore?.format != "PRESENTIEL" && (
                      <Box width="180px">
                        <TextFieldFake
                          id="outil"
                          style={{ ...textFieldStyle, width: "200px" }}
                          select
                          label="Outil"
                          variant="filled"
                          value={getValue("outil")}
                          InputProps={{
                            shrink: "true",
                            classes: {
                              root: classes.textFieldRoot,
                              focused: classes.textFieldRootAlt,
                              input: classes.textFieldRootAlt2,
                            },
                          }}
                          InputLabelProps={{
                            classes: { root: classes.textFieldLabel },
                          }}
                          SelectProps={{
                            classes: { root: classes.selectProps },
                          }}
                          onChange={handleChange("outil")}
                        >
                          {outilsChoix.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextFieldFake>
                      </Box>
                    )}
                    <Box marginLeft="30px" width="245px">
                      <TextFieldFake
                        id="outilUrl"
                        value={getValue("outilUrl")}
                        style={{
                          ...textFieldStyle,
                          marginLeft: "20px",
                          width: "calc(100% - 20px)",
                        }}
                        variant="filled"
                        InputProps={{
                          shrink: "true",
                          classes: {
                            root: classes.textFieldRoot,
                            focused: classes.textFieldRootAlt,
                            input: classes.textFieldRootAlt2,
                          },
                        }}
                        InputLabelProps={{
                          classes: { root: labelClass("outilUrl") },
                        }}
                        SelectProps={{ classes: { root: classes.selectProps } }}
                        label="adresse Url événement"
                        onChange={handleChange("outilUrl")}
                      />
                    </Box>
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  height="60px"
                  marginTop="40px"
                >
                  {!getValue("checked") && (
                    <Box width="100px">
                      <TextFieldFake
                        id="nbmax"
                        error={nbMaxError}
                        variant="filled"
                        style={{
                          margin: marginText,
                          width: "100px",
                          position: "relative",
                          bottom: "5px",
                        }}
                        type="number"
                        required
                        value={getValue("nbMax")}
                        InputProps={{
                          shrink: "true",
                          classes: {
                            root: classes.textFieldRoot,
                            focused: classes.textFieldRootAlt,
                            input: classes.textFieldRootAlt2,
                          },
                        }}
                        InputLabelProps={{
                          classes: { root: classes.textFieldLabel },
                        }}
                        SelectProps={{ classes: { root: classes.selectProps } }}
                        onChange={handleChange("nbMax")}
                        label="Capacité"
                        className={classes.textField}
                      />
                    </Box>
                  )}

                  <Box
                    display="flex"
                    flexDirection="column"
                    padding="13px"
                    position="relative"
                    bottom="0px"
                  >
                    <Typography
                      style={{
                        color: "#757575",
                        fontSize: "0.7rem",
                        position: "relative",
                        bottom: "2px",
                      }}
                    >
                      Catalogue
                    </Typography>

                    {modalStore && (
                      <Switch
                        checked={getValue("checked")}
                        onChange={handleChange("checked")}
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: green[600],
                            "&:hover": {
                              backgroundColor: alpha(
                                green[600],
                                theme.palette.action.hoverOpacity
                              ),
                            },
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: green[600],
                            },
                        }}
                      />
                    )}
                  </Box>

                  <Box width="160px">
                    {!getValue("checked") && (
                      <div
                        style={{
                          width: "160px",
                          color: "#757575",
                          fontSize: "0.7rem",
                          marginTop: "6px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#757575",
                            fontSize: "0.7rem",
                            marginBottom: "9px",
                          }}
                        >
                          Date
                        </Typography>
                        <DatePicker
                          customInput={<ExampleCustomInput />}
                          locale="fr"
                          timeInputLabel="horaire:"
                          dateFormat="dd/MM/yyyy hh:mm aa"
                          showTimeSelect
                          popperPlacement="top"
                          popperClassName={{ zIndex: 999 }}
                          timeIntervals={15}
                          //dateFormat="Pp"
                          onSelect={() => {}}
                          onChange={handleDateChange}
                          selected={
                            modalStore && modalStore["date"]
                              ? modalStore["date"]
                              : event?.date
                              ? getDate(event.date)
                              : nullConference().date
                          }
                        />
                      </div>
                    )}

                    {/* {!getValue('checked') && <div style={{ width: "160px", display: "flex", flexDirection: "column", color: "#757575", fontSize: "0.7rem", position: "relative", bottom: "-10px" }}>
										<Typography style={{ color: "#757575", fontSize: "0.7rem", marginBottom: "9px" }}>
											Date
										</Typography>

										<DatePicker
											customInput={<ExampleCustomInput />}
											locale="fr"
											timeInputLabel="horaire:"
											dateFormat="dd/MM/yyyy hh:mm aa"
											showTimeSelect
											popperPlacement="left"
											popperClassName={classes.datePicker}

											timeIntervals={15}

											//dateFormat="Pp"
											onSelect={() => { }}
											onChange={handleDateChange}
											selected={modalStore && modalStore['date'] ? modalStore['date'] : event?.date ? getDate(event.date) : nullConference().date}
										/>

									</div>

									} */}
                  </Box>
                  {!getValue("checked") && (
                    <Box width="90px" marginLeft="15px">
                      <TextFieldFake
                        id="duree"
                        variant="filled"
                        style={{
                          margin: marginText,
                          width: "100px",
                          position: "relative",
                          bottom: "4px",
                        }}
                        type="number"
                        required
                        value={getValue("duree")}
                        InputProps={{
                          shrink: "true",
                          classes: {
                            root: classes.textFieldRoot,
                            focused: classes.textFieldRootAlt,
                            input: classes.textFieldRootAlt2,
                          },
                        }}
                        InputLabelProps={{
                          classes: { root: classes.textFieldLabel },
                        }}
                        SelectProps={{ classes: { root: classes.selectProps } }}
                        onChange={handleChange("duree")}
                        label="Durée (mn)"
                        inputProps={{
                          step: "5",
                        }}
                        className={classes.textField}
                      />
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="row">
                  {!getValue("checked") && (
                    <Box width="70px">
                      <TextFieldFake
                        id="prix"
                        variant="filled"
                        error={prixError}
                        style={{ margin: marginText, width: "70px" }}
                        type="number"
                        required
                        InputProps={{
                          shrink: "true",
                          classes: {
                            root: classes.textFieldRoot,
                            focused: classes.textFieldRootAlt,
                            input: classes.textFieldRootAlt2,
                          },
                        }}
                        InputLabelProps={{
                          classes: { root: classes.textFieldLabel },
                        }}
                        SelectProps={{ classes: { root: classes.selectProps } }}
                        value={getValue("prix")}
                        onChange={handleChange("prix")}
                        label="Prix (€)"
                        className={classes.textField}
                      />
                    </Box>
                  )}

                  <Box width="200px" marginLeft="20px">
                    <TextFieldFake
                      id="langue"
                      variant="filled"
                      style={{ margin: marginText, width: "200px" }}
                      select
                      InputProps={{
                        shrink: "true",
                        classes: {
                          root: classes.textFieldRoot,
                          focused: classes.textFieldRootAlt,
                          input: classes.textFieldRootAlt2,
                        },
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textFieldLabel },
                      }}
                      SelectProps={{ classes: { root: classes.selectProps } }}
                      label="Langue de l'événement"
                      value={getValue("langue")}
                      onChange={handleChange("langue")}
                    >
                      {langues.map((langue) => (
                        <MenuItem
                          key={langue.value.toUpperCase()}
                          value={langue.value}
                        >
                          {langue.value}
                        </MenuItem>
                      ))}
                    </TextFieldFake>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    padding="13px"
                    position="relative"
                    bottom="0px"
                  >
                    <Typography
                      style={{
                        color: "#757575",
                        fontSize: "0.7rem",
                        position: "relative",
                        bottom: "2px",
                      }}
                    >
                      Un replay sera disponible
                    </Typography>
                    {modalStore && (
                      <Switch
                        checked={getValue("futurReplay")}
                        onChange={handleChange("futurReplay")}
                        // color="secondary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: green[600],
                            "&:hover": {
                              backgroundColor: alpha(
                                green[600],
                                theme.palette.action.hoverOpacity
                              ),
                            },
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: green[600],
                            },
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </div>
            </div>
          </form>
          <Box
            margin="10px 20px 0px 70px"
            display="flex"
            width="400px"
            flexDirection="column"
          >
            <Box height="400px">
              {modalStore && (
                <TextEditor
                  setContent={setNewDocumentHtml}
                  setNewDocumentHtml={setNewDocumentHtml}
                  content={cleanHtml(event?.documentHtml)}
                />
              )}
            </Box>
            <Box width="300px" marginTop="0px" marginLeft="0px">
              <Typography style={{ fontSize: "0.7rem", color: grey[600] }}>
                Image
              </Typography>

              <Box
                className={labelClass("image")}
                display="flex"
                flexDirection="row"
                marginTop="20px"
                justifyItems="start"
                justifyContent="start"
              >
                <Box width="30px">
                  {
                    <ImageUpload
                      setSpinner={setSpinner}
                      label="+ photos"
                      modalStore={formData}
                      setModalStore={setFormData}
                    />
                  }
                </Box>

                {!modalStore?.imageStore && event?.base64Image && (
                  <img
                    style={{
                      marginLeft: isClone ? "6vw" : "6vw",
                      width: "7vw",
                      height: "7vw",
                      objectFit: "cover",
                    }}
                    src={
                      modalStore?.imageStore?.base64Image || event?.base64Image
                    }
                  ></img>
                )}

                {
                  <div
                    style={{
                      display: "flex",
                      padding: "0.25vw",
                      alignItems: "center",
                      marginLeft: "40px",
                    }}
                  >
                    {!modalStore?.base64data &&
                    modalStore?.imageStore?.compressedS ? (
                      <ShowImage conferenceImageData={modalStore.imageStore} />
                    ) : null}
                    {spinner ? <CircularProgress size={"2vw"} /> : null}
                  </div>
                }
              </Box>
              <Box marginTop="20px">
                {extractCategories(modalStore) && (
                  <div style={{ marginBottom: "20px" }}>
                    <Typography
                      style={{ fontSize: "0.7rem", color: grey[600] }}
                    >
                      Catégories
                    </Typography>
                  </div>
                )}

                <Box height="100px" overflow="scroll">
                  {extractCategories(modalStore) &&
                    extractCategories(modalStore).map((category, index) => (
                      <DisplayCategory
                        key={index}
                        handleSelectionClick={handleSelectionClick}
                        index={index}
                        category={category}
                      />
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ShowImage = ({ conferenceImageData, size = "S" }) => {
  const images = [];

  //  images.push( URL.createObjectURL(conferenceImageData.original))

  if (typeof conferenceImageData.compressedS === "string") {
    images.push(conferenceImageData.compressedS);
  } else {
    images.push(URL.createObjectURL(conferenceImageData.compressedS));
  }

  //images.push(URL.createObjectURL(conferenceImageData.compressedM))
  //  images.push( URL.createObjectURL(conferenceImageData.compressedL))

  return (
    <div>
      {images.map((image, index) => (
        <img
          key={index}
          style={{
            position: "relative",
            bottom: "4px",
            width: "4vw",
            height: "4vw",
            objectFit: "cover",
          }}
          src={image}
        />
      ))}
    </div>
  );
};

export default EventForm;

const extractCategories = (modalStore) => {
  if (modalStore && modalStore.categories) {
    return modalStore.categories.split("|");
  }
  return null;
};

const DisplayCategory = ({ index, category, handleSelectionClick }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "250px",
        borderRadius: "10px",
        padding: "2px",
        margin: "4px",
        backgroundColor: grey[200],
      }}
    >
      <div>{index + 1}:</div>
      <div style={{ width: "180px", textAlign: "center" }}>{category}</div>

      <CancelIcon
        onClick={handleSelectionClick(index)}
        style={{ marginLeft: "10px", fontSize: "20px", color: grey[500] }}
      />
    </div>
  );
};
