import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { DateTime } from "luxon";
import Modal from "@mui/material/Modal";
import { Snackbar, TextField } from "@mui/material";
import Fade from "@mui/material/Fade";
import Adjust from "@mui/icons-material/Adjust";
import styled from "styled-components";
import {
  updateEventBatch,
  updateCycleBatch,
  handleDeleteCycle,
  handleDeleteEvent,
  downloadImageBatch,
} from "../helpers/batches";
import { fetchConferenceData, fetchCycleData } from "../helpers/fetchers";
import { setEventsRows } from "../../features/events/eventsSlice";
import { setCyclesRows } from "../../features/cycles/cyclesSlice";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { grey } from "@mui/material/colors";
import {
  handleClose10,
  handleClose11,
  handleCloseOrganism10,
} from "../handleClose";
import useStylesOld from "../style/textFields";
import Backdrop from "@mui/material/Backdrop";
// redux
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";

import { localeText } from "../general";
import Button from "@mui/material/Button";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import { colorBlue, colorYellow } from "../BO-style";

import { ColorButton } from "../../components/BO-style";

import { getDate } from "../general";

import { frFR } from "@mui/material/locale";

import { colorTaupeEncorePlusClair } from "../../AppStyle";
import { orange, red, green, blue } from "@mui/material/colors";
import EventForm from "../eventForm";
import CycleForm from "../cycleForm";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  cyclesbyOrganisateur,
  eventsbyCycle,
  eventsbyOrganisateur,
  listCycles,
} from "../../graphql/queries";
import { updateEvent } from "../../graphql/mutations";

LicenseInfo.setLicenseKey(
  "4a20a34c209eb84876086372a8290749Tz00ODczNSxFPTE2OTE1OTI2NTcxNjEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const BROUILLON = "brouillon";

const mapFormat = (format) => {
  if (format === "MEET" || !format) return "Meet";
  if (format === "ZOO") return "Zoom";
  if (format === "TEAMS") return "Teams";
  return format;
};

const CompanyPreparation = (props) => {
  const secondScreenHook = useState(null);
  const setSecondScreen = secondScreenHook[1];
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionModelCycle, setSelectionModelCycle] = useState([]);
  const [isEventForm, setIsEventForm] = useState(false);
  const [isEventCycleForm, setIsEventCycleForm] = useState(false);
  const [isEventCycleEdit, setIsEventCycleEdit] = useState(false);
  const [formData, setFormData] = useState(null);
  const [cycleFormData, setCycleFormData] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [showCycleButton, setShowCycleButton] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [rows, setRows] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [eventsCallback, setEventsCallback] = useState([]);
  const conferenceStore = useSelector((state) => state.eventsReducer);
  const data = useSelector((state) => state.dataReducer);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const company = data.companies[0];

  const conferenceData = useSelector(
    (state) => state.eventsReducer
  )?.data.filter((conference) => {
    if (selectionModelCycle.length === 0) return true;
    return selectionModelCycle.includes(conference.cycleId);
  });
  const cyclesData = useSelector((state) => state.cyclesReducer)?.data;
  // const cyclesStore = useSelector(state => state.cyclesReducer)
  var transforemedConferenceData =
    conferenceData === null ? [] : conferenceData;
  var transforemedConferenceData = transforemedConferenceData.filter(
    (elem) => elem.organisateurId === company.id
  );
  const [conferenceId, setConferenceId] = useState(null);
  const [isClone, setIsClone] = useState(false);
  const [restrictDate, setRestrictDate] = useState(0);
  const eventRef = useRef(null);
  void conferenceId;
  void isClone;
  const formateurData = useSelector((state) => state.dataReducer);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    console.log(selectionModel);
  }, [selectionModel]);

  useEffect(async () => {
    await API.graphql(
      graphqlOperation(eventsbyOrganisateur, {
        organisateurId: company.id,
      })
    ).then((res) => {
      setConferences(res.data.eventsbyOrganisateur.items);
      setRows(
        res.data.eventsbyOrganisateur.items.map((elem) => {
          let etat = "incomplet";
          let nbAttending = 0;

          if (typeof elem?.attendingNb === "number")
            nbAttending = elem.attendingNb;
          if (
            elem.title1 &&
            elem.imageKey &&
            (elem.prix || elem.prix === 0) &&
            elem.nbMax &&
            elem.date &&
            elem.outilUrl
          )
            etat = "publiable";
          if (elem.state === "published" && formateurData.state !== "published")
            etat = "wait";
          if (elem.state === "published" && formateurData.state === "published")
            etat = "publié";
          if (elem.state === "CANCELED") etat = "canceled";
          let cycleTitle = "";
          if (elem.cycleId) {
            const cycle = cyclesData.find((cycle) => cycle.id === elem.cycleId);
            cycleTitle = cycle?.title;
          }
          return {
            id: elem.id,
            etat: etat,
            nom: elem.title1,
            organisateur: "---------------",
            prix: elem.prix,
            cycleTitle: cycleTitle,
            outil: [mapFormat(elem.outil), elem.format],
            date: elem.date ? getDate(elem.date) : BROUILLON,
            modifiable: elem.state === "CANCELED" || nbAttending === 0, // elem.state == 'CANCELED' || nbAttending > 0 ? false : true,
            rawDate: elem.date,
            quizz: false,
            sondage: false,
            image: elem.base64Image,
            documentHtml: elem.documentHtml ? elem.documentHtml : null,
            available: elem?.nbMax - elem?.nbReserved - elem?.attendingNb,
            replayStatus: elem.replayStatus,
          };
        })
      );
    });
    // fetchConferenceData();
    fetchCycleData();
  }, [company]);

  const refreshConferences = async () => {
    await API.graphql(
      graphqlOperation(eventsbyOrganisateur, {
        organisateurId: company.id,
      })
    ).then((res) => {
      setConferences(res.data.eventsbyOrganisateur.items);
    });
  };

  useEffect(() => {
    updateRows();
  }, [conferences]);

  const updateRows = async () => {
    let tmpConferences = [];
    for (const elem of conferences) {
      const imageRes = await Storage.list(`${elem.formateurId}/conferences`, {
        level: "private",
      });
      const regex = new RegExp("conferences" + "/(.+)");
      const images = imageRes
        .map((row) => ({ key: row.key, fileName: row.key.match(regex)[1] }))
        .filter((row) => row.fileName[0] === "S");
      const image = images.find(
        (image) => image.fileName && image.fileName.includes(elem.imageKey)
      );
      let base64 = "";
      if (image && image.fileName) {
        const fileName = image.fileName.split(".")[0];
        const extension = image.fileName.split(".")[1];
        base64 = await downloadImageBatch(
          elem.formateurId,
          fileName,
          extension,
          "conferences"
        );
      }
      let etat = "incomplet";
      let nbAttending = 0;

      if (typeof elem?.attendingNb === "number") nbAttending = elem.attendingNb;
      if (
        elem.title1 &&
        elem.imageKey &&
        (elem.prix || elem.prix === 0) &&
        elem.nbMax &&
        elem.date &&
        elem.outilUrl
      )
        etat = "publiable";
      if (elem.state === "published" && formateurData.state !== "published")
        etat = "wait";
      if (elem.state === "published" && formateurData.state === "published")
        etat = "publié";
      if (elem.state === "CANCELED") etat = "canceled";
      let cycleTitle = "";
      if (elem.cycleId) {
        const cycle = cyclesData.find((cycle) => cycle.id === elem.cycleId);
        cycleTitle = cycle?.title;
      }
      tmpConferences.push({
        id: elem.id,
        etat: etat,
        nom: elem.title1,
        organisateur: "---------------",
        prix: elem.prix,
        cycleTitle: cycleTitle,
        outil: [mapFormat(elem.outil), elem.format],
        date: elem.date ? getDate(elem.date) : BROUILLON,
        rawDate: elem.date,
        modifiable: elem.state === "CANCELED" || nbAttending === 0, // elem.state == 'CANCELED' || nbAttending > 0 ? false : true,
        quizz: false,
        sondage: false,
        image: base64 ? base64 : "",
        documentHtml: elem.documentHtml ? elem.documentHtml : null,
        available: elem?.nbMax - elem?.nbReserved - elem?.attendingNb,
        replayStatus: elem.replayStatus,
      });
    }
    setRows(tmpConferences);
  };

  const dispatch = useDispatch();
  const classes = useStylesMain();

  const columns = [
    {
      field: "etat",
      width: 50,
      headerName: "Etat",
      renderHeader: () => <TableHeader header={"Etat"} />,
      renderCell: (params) => {
        const state = params.row.etat;
        let color = red[500];
        let caption = "incomplet";
        let title =
          "Il manque des informations nécessaires à la publication de votre conférence";
        if (state === "publié") {
          color = green[300];
          caption = "en ligne";
          title =
            "Vous n'avez plus rien à faire, la conférence sera en ligne sous peu";
        }
        if (state === "publiable") {
          caption = "prêt";
          color = orange[300];
          title = "La conférence est prête à être publiée";
        }
        if (state === "wait") {
          caption = "en attente";
          color = orange[100];
          title =
            'Pour que la conférence soit mise en ligne veuillez publier votre page conférencier dans la rubrique "ma page"';
        }
        if (state === "canceled") {
          caption = "suspendu";
          color = orange[400];
        }
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Box
              marginTop="10px"
              display="flex"
              flexDirection="column"
              alignContent="center"
              alignItems="center"
            >
              <LightTooltip title={title}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                >
                  <Dot color={color} />
                  <Box>
                    <Typography
                      style={{ fontSize: "0.5rem", marginTop: "2px" }}
                    >
                      {caption}
                    </Typography>
                  </Box>
                </Box>
              </LightTooltip>
            </Box>
          </div>
        );
      },
    },
    {
      field: "image",
      width: 60,
      renderHeader: () => <TableHeader header={"Image"} />,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const image = params.row.image;
        return (
          <img
            alt=""
            style={{ width: "60px", height: "60px", objectFit: "cover" }}
            src={image}
          ></img>
        );
      },
    },
    {
      field: "nom",
      width: 130,
      headerName: "Nom de la conférence",
      renderHeader: () => <TableHeader header={"Nom"} />,
      renderCell: (params) => {
        const state = params.row.nom;
        return <div>{state}</div>;
      },
    },
    {
      field: "cycleTitle",
      width: 100,
      headerName: "Cycle",
      renderHeader: () => <TableHeader header={"Cycle"} />,
      renderCell: (params) => {
        const state = params.row.cycleTitle;
        return <div>{state}</div>;
      },
    },
    {
      field: "outil",
      width: 60,
      headerName: "Outil",
      cellClassName: "theme--cell",
      renderCell: (params) =>
        params.value[1] === "PRESENTIEL" ? "-------" : params.value[0],
      renderHeader: () => <TableHeader header={"Outil"} />,
    },
    {
      field: "date",
      width: 100,
      headerName: "Date",
      cellClassName: "theme--cell",
      valueFormatter: (params) =>
        params.value === BROUILLON
          ? "modèle"
          : params.value.toLocaleDateString("fr-FR", options),
      renderHeader: () => (
        <TableHeader
          header={"Date"}
          restrictDate={restrictDate}
          setRestrictDate={setRestrictDate}
        />
      ),
    },
    {
      field: "horaire",
      width: 55,
      headerName: "Horaire",
      valueGetter: getFullName,
      cellClassName: "theme--cell",
      renderHeader: () => <TableHeader header={"Horaire"} />,
      valueFormatter: (params) => {
        if (params.value === BROUILLON) return "---";
        const dt = DateTime.fromISO(params.value.toISOString());
        return dt.toFormat("HH:mm");
      },
    },
    {
      field: "modifiable",
      width: 80,
      headerName: "Modifiable",
      cellClassName: "theme--cell",
      renderHeader: () => <TableHeader header={"Modifiable"} />,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            justifyItems="center"
          >
            {params.value === true ? (
              <LightTooltip title="événement totalement modifiable">
                <LockOpenIcon style={{ color: green[500] }} small="true" />
              </LightTooltip>
            ) : (
              <LightTooltip title="Vous avez au moins un inscrit. Seules des corrections minimes du texte sont autorisées">
                <LockIcon small="true" />
              </LightTooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      width: 60,
      renderHeader: () => <TableHeader header={"Modifier"} />,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setIsClone(false);
          setIsEventForm(true);
          // eventRef.current?.scrollIntoView({behavior: 'smooth'})
          setSecondScreen({
            modalId: "1-0",
            state: "modify",
            eventId: params.row.id,
          });
          setConferenceId(params.row.id);
        };
        return (
          <Box
            width="40px"
            padding="5px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
          >
            <ColorButton onClick={onClick}>
              <EditIcon />
            </ColorButton>
          </Box>
        );
      },
    },
    {
      field: "dupliquer",
      width: 60,
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Cloner"} />
        </Box>
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setIsClone(true);
          setIsEventForm(true);
          // eventRef.current?.scrollIntoView({behavior: 'smooth'})
          setSecondScreen({
            modalId: "1-0",
            state: "clone",
            eventId: params.row.id,
          });
          setConferenceId(params.row.id);
        };
        return (
          <Box
            width="40px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            justifyItems="center"
          >
            <ColorButton onClick={onClick}>
              <FileCopyIcon />
            </ColorButton>
          </Box>
        );
      },
    },
    {
      field: "delete",
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Supprimer"} />
        </Box>
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          deleteSingleEvent(params.row.id);
          // setIsClone(false)
          // setConferenceId(params.row.id)
        };
        return (
          <Box
            width="40px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            justifyItems="center"
          >
            <ColorButton onClick={onClick}>
              <DeleteIcon />
            </ColorButton>
          </Box>
        );
      },
    },
    {
      field: "publier",
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Action"} />
        </Box>
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const publishRow = () => {
          publishSingleAction(params.row.id);
        };
        const unpublishRow = () => {
          unpublishSingleAction(params.row.id);
        };
        return (
          <Box>
            {params.row.etat === "publiable" ? (
              <StyledButtonTab onClick={publishRow}>Publier</StyledButtonTab>
            ) : params.row.etat === "publié" && params.row.modifiable ? (
              <StyledButtonTab onClick={unpublishRow}>
                Dépublier
              </StyledButtonTab>
            ) : params.row.etat === "wait" ? (
              <StyledButtonTab onClick={unpublishRow}>Retirer</StyledButtonTab>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(setEventsRows(selectionModel));
  }, [selectionModel, dispatch]);

  useEffect(() => {
    dispatch(setCyclesRows(selectionModelCycle));
  }, [selectionModelCycle, dispatch]);

  const publishSingleAction = (id) => {
    updateEventBatch({ id: id, state: "published" })
      .then(() => {
        refreshConferences();
        updateRows();
      })
      .catch((err) => console.log(err));
  };

  const unpublishSingleAction = (id) => {
    updateEventBatch({ id: id, state: "unpublished" })
      .then(() => {
        refreshConferences();
        updateRows();
      })
      .catch((err) => console.log(err));
  };

  // END OF DELETE-ACTION
  const myRef = React.useRef(null);
  const myRefCycle = React.useRef(null);

  const clickHandlerForm = () => {
    if (isEventForm) {
      const tmpData = { ...formData, organisateurId: company.id };
      if (tmpData.formateurId === null || tmpData.formateurId === "") {
        setOpenError(true);
        return;
      }
      handleCloseOrganism10(
        tmpData,
        setFormData,
        secondScreenHook,
        refreshConferences
      );
    }
    setIsEventForm(!isEventForm);
  };

  const clickHandlerFormCreate = () => {
    setSecondScreen({
      modalId: "1-0",
      state: "create",
    });
    // setFormData(null)
    setIsEventForm(true);
  };

  const clickHandlerFormCancel = () => {
    secondScreenHook[1](null);
    setIsEventForm(!isEventForm);
  };

  const clickHandlerCycleForm = () => {
    secondScreenHook[1]({
      state: "general",
    });
    if (isEventCycleForm) {
      const tmpData = { ...cycleFormData, organisateurId: company.id };
      handleClose11(tmpData, setCycleFormData, secondScreenHook, updateRows);
    }
    setIsEventCycleForm(!isEventCycleForm);
  };

  const clickHandlerCycleFormSave = () => {
    secondScreenHook[1]({
      state: "general",
    });
    if (isEventCycleForm || isEventCycleEdit) {
      const tmpData = { ...cycleFormData, organisateurId: company.id };
      handleClose11(
        tmpData,
        setCycleFormData,
        secondScreenHook,
        updateRows,
        eventsCallback,
        setShouldUpdate
      );
    }
    setSelectionModel([]);
    updateRows();
    setIsEventCycleForm(false);
    setIsEventCycleEdit(false);
  };

  const clickHandlerCycleFormCancel = () => {
    secondScreenHook[1](null);
    setIsEventCycleForm(false);
    setIsEventCycleEdit(false);
  };

  const deleteSingleEvent = async (id) => {
    await handleDeleteEvent({ id: id })
      .then(async () => {
        refreshConferences();
        // updateRows();
        setSelectionModelCycle([]);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.root} style={{ margin: "2vw 0 0 0vw" }}>
      <Box>
        <Box>
          <Box width="100%" marginTop="20px">
            <Typography variant="h4" fontSize="1.3rem" fontWeight={900}>
              {"Mettez vos conferences, cycles de conferences et replays en ligne sur pekoia".toUpperCase()}
            </Typography>
          </Box>
          <Box width="100%" marginTop="30px">
            <Typography style={{ fontSize: "0.9rem" }}>
              {
                "À noter que l'inscription à la brique de paiement (délai de validation sous 48 heures) est obligatoire pour créer une première conférence payante ou un premier cycle de conférences payant sur Pekoia."
              }
            </Typography>
          </Box>
        </Box>
        <Box width="100%" marginTop="30px">
          <Box borderBottom="1px solid black">
            <Typography
              ref={eventRef}
              variant="h4"
              fontSize="1.5rem"
              margin="10px"
            >
              Conférences
            </Typography>
          </Box>
          {isEventForm ? (
            <EventForm
              style={{ width: "64vw" }}
              formData={formData}
              setFormData={setFormData}
              secondScreenHook={secondScreenHook}
              setShowButton={setShowButton}
              companyId={company.id}
            />
          ) : null}
          {isEventForm ? (
            <div>
              <StyledButton
                onClick={clickHandlerForm}
                style={{ marginBottom: "10px" }}
                disabled={!showButton}
              >
                Sauvegarder
              </StyledButton>
              <StyledButton
                onClick={clickHandlerFormCancel}
                style={{ marginBottom: "10px" }}
              >
                Annuler
              </StyledButton>
            </div>
          ) : (
            <StyledButton
              onClick={clickHandlerFormCreate}
              style={{ marginBottom: "10px" }}
            >
              Créer une nouvelle conférence
            </StyledButton>
          )}
          <DataTable
            restrictDate={restrictDate}
            secondScreenHook={secondScreenHook}
            myRef={myRef}
            rows={rows}
            columns={columns}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
          <Box width="100%" marginBottom="30px">
            <Typography style={{ fontSize: "0.9rem" }}>
              {
                "Certaines actions (dépublication, suppression, changement de prix, changement de date ...) ne sont plus possibles à partir du moment où une personne est déjà inscrite à votre conférence. Pour tout besoin de modification non permise par le back office, veuillez nous contacter: contact@pekoia.com"
              }
            </Typography>
          </Box>
          {/* <Divider orientation="vertical" flexItem /> */}
        </Box>
        <Box width="100%">
          <Box borderBottom="1px solid black">
            <Typography
              ref={eventRef}
              variant="h4"
              fontSize="1.5rem"
              margin="10px"
            >
              Cycles
            </Typography>
          </Box>
          <Typography style={{ fontSize: "0.9rem", marginTop: "10px" }}>
            {
              "Veuillez sélectionner les conférences que vous souhaitez grouper au sein d'un cycle dans le tableau ci-dessus puis cliquez sur le bouton ci-dessous."
            }
          </Typography>
          <Typography style={{ fontSize: "0.9rem" }}>
            {
              "À noter que si vous souhaitez vendre seulement le cycle (pas de vente de conférences à l'unité), veuillez ne pas publier les conférences individuelles et les laisser en état 'prêt'"
            }
          </Typography>
          {isEventCycleForm || isEventCycleEdit ? (
            <CycleForm
              formData={cycleFormData}
              setFormData={setCycleFormData}
              secondScreenHook={secondScreenHook}
              setShowButton={setShowCycleButton}
              selectionModel={selectionModel}
              setEventsCallback={setEventsCallback}
            />
          ) : null}
          {isEventCycleForm || isEventCycleEdit ? (
            <div>
              <StyledButton
                onClick={clickHandlerCycleFormSave}
                style={{ marginBottom: "10px" }}
                disabled={!showCycleButton}
              >
                Sauvegarder
              </StyledButton>
              <StyledButton
                onClick={clickHandlerCycleFormCancel}
                style={{ marginBottom: "10px" }}
              >
                Annuler
              </StyledButton>
            </div>
          ) : (
            <StyledButton
              style={{ marginBottom: "10px" }}
              disabled={selectionModel.length >= 2 ? false : true}
              onClick={clickHandlerCycleForm}
            >
              Créer un cycle
            </StyledButton>
          )}
          <DisplayCycles
            shouldUpdate={shouldUpdate}
            setShouldUpdate={setShouldUpdate}
            myRef={myRefCycle}
            selectionModelCycle={selectionModelCycle}
            setSelectionModelCycle={setSelectionModelCycle}
            setIsEventCycleForm={setIsEventCycleForm}
            setIsEventCycleEdit={setIsEventCycleEdit}
            secondScreenHook={secondScreenHook}
            selectionModel={selectionModel}
          />
        </Box>
        <Box width="100%">
          <Box borderBottom="1px solid black">
            <Typography variant="h4" fontSize="1.5rem" margin="10px">
              Replays / Conférences à la demande
            </Typography>
          </Box>
          <Typography style={{ fontSize: "0.9rem", marginTop: "10px" }}>
            {
              "Nous nous chargeons de la mise en ligne de vos replays afin de vérifier qu'ils respectent les différentes réglementations en vigueur (notamment l'absence de visage des participants). Si votre vidéo est validée, elle sera mise en ligne dans les 48 heures après que vous l'ayez envoyé via le bouton 'publier' ci-dessous"
            }
          </Typography>
          <DisplayReplays allRows={rows} />
        </Box>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Vous devez selectionner un conférencier. Si aucun conférencier n'apparaît, créez en un dans l'onglet 'Membres'"
        />
      </Box>
    </div>
  );
};

export default CompanyPreparation;

const DisplayReplays = ({ allRows }) => {
  const conferenceData = useSelector((state) => state.eventsReducer)?.data;
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [price, setPrice] = useState(0);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [displayButton, setDisplayButton] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  var transforemedConferenceData =
    conferenceData === null ? [] : conferenceData;
  const now = new Date();
  const time = (now.getTime() - (now.getTime() % 86400000)) / 1000;
  const classes = useStylesOld();
  transforemedConferenceData = allRows.filter((e) => e.rawDate < time);
  var rows = transforemedConferenceData;

  const columns = [
    {
      field: "etat",
      width: 50,
      headerName: "Etat",
      renderHeader: () => <TableHeader header={"Etat"} />,
      renderCell: (params) => {
        const state = params.row.replayStatus;
        let color = red[500];
        let caption = "hors ligne";
        let title = "Le replay n'est pas publié";
        if (state === "AVAILABLE") {
          color = green[300];
          caption = "en ligne";
          title = "Le replay est en ligne";
        }
        if (state === "PROCESSING") {
          caption = "En traitement";
          color = orange[200];
          title = "Le replay est en traitement, il sera en ligne sous peu";
        }

        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Box
              marginTop="10px"
              display="flex"
              flexDirection="column"
              alignContent="center"
              alignItems="center"
            >
              <LightTooltip title={title}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                >
                  <Dot color={color} />
                  <Box>
                    <Typography
                      style={{ fontSize: "0.5rem", marginTop: "2px" }}
                    >
                      {caption}
                    </Typography>
                  </Box>
                </Box>
              </LightTooltip>
            </Box>
          </div>
        );
      },
    },
    {
      field: "image",
      width: 60,
      renderHeader: () => <TableHeader header={"Image"} />,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const image = params.row.image;
        return (
          <img
            alt=""
            style={{ width: "60px", height: "60px", objectFit: "cover" }}
            src={image}
          ></img>
        );
      },
    },
    {
      field: "nom",
      width: 430,
      headerName: "Nom du replay",
      renderHeader: () => <TableHeader header={"Nom"} />,
      renderCell: (params) => {
        const state = params.row.nom;
        return <div>{state}</div>;
      },
    },
    {
      field: "prix",
      width: 60,
      headerName: "Prix du replay",
      renderHeader: () => <TableHeader header={"Prix"} />,
      renderCell: (params) => {
        const state = params.row.prix;
        return <div>{state}€</div>;
      },
    },
    // {
    // 	field: "action",
    // 	width: 70,
    // 	renderHeader: () => (
    // 		<TableHeader header={"Modifier"} />
    // 	),
    // 	sortable: false,
    // 	disableClickEventBubbling: true,
    // 	renderCell: (params) => {
    // 		const onClick = () => {
    // 		};
    // 		return <Box width="40px" padding="5px" display="flex" flexDirection="row" justifyContent="center" alignContent="center">
    // 			<ColorButton
    // 				onClick={onClick}
    // 			><EditIcon /></ColorButton>
    // 		</Box>
    // 	}
    // },
    {
      field: "publier",
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Action"} />
        </Box>
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const publishRow = () => {
          setId(params.row.id);
          setName(params.row.nom);
          setOpen(true);
          // publishSingleAction(params.row.id)
        };
        const unpublishRow = () => {
          // unpublishSingleAction(params.row.id)
        };
        return (
          <Box>
            {params.row.replayStatus !== "AVAILABLE" ? (
              <StyledButtonTab onClick={publishRow}>Publier</StyledButtonTab>
            ) : (
              <StyledButtonTab onClick={unpublishRow}>
                Dépublier
              </StyledButtonTab>
            )}
          </Box>
        );
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLink("");
    setPrice(0);
    setId("");
    setName("");
    setOpen(false);
  };

  useEffect(() => {
    if (titleError || priceError) {
      setDisplayButton(false);
      return;
    }
    setDisplayButton(true);
  }, [titleError, priceError]);

  useEffect(() => {
    checkError();
  }, [link, price]);

  const handleChange = (key) => {
    return (e) => {
      let value = e.target.value;
      if (key === "link") {
        setLink(value);
      } else {
        setPrice(e.target.value);
      }
      // checkError()
      // if (titleError || priceError) {
      // 	setDisplayButton(false)
      // 	return;
      // }
      // setDisplayButton(true)
    };
  };

  const setEventStatus = (id) => {
    API.graphql(
      graphqlOperation(updateEvent, {
        input: { id: id, replayStatus: "PROCESSING" },
      })
    )
      .then((res) => {
        handleClose();
        fetchConferenceData();
      })
      .catch((err) => {
        handleClose();
      });
  };

  const checkError = () => {
    const regex = /^https:\/\/(www.)?(we.tl)\/.+$/m;
    if (link.match(regex)) {
      setTitleError(false);
    } else {
      setTitleError(true);
    }
    if (price < 0) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  };

  const sendLink = () => {
    checkError();
    if (titleError || priceError) {
      return;
    }
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: 24,
    // p: 4,
  };

  return (
    <Box marginTop="20px" width="100%">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box style={style}>
            <Box>
              <Typography id="modal-modal-title" textAlign="center">
                Veuillez renseigner le lien WeTransfer où se trouve
                l'enregistrement de votre conférence.
              </Typography>
            </Box>
            <Box marginTop="15px" marginBottom="15px">
              <TextField
                // error={titleError}
                id="link"
                value={link}
                className={classes.textField}
                fullWidth
                required
                label="Lien"
                variant="filled"
                onChange={handleChange("link")}
                // helperText={titleError ? 'Le lien doit être un lien WeTransfer' : ''}
                InputProps={{
                  shrink: "true",
                  classes: {
                    root: classes.textFieldRoot,
                    focused: classes.textFieldRootAlt,
                    input: classes.textFieldRootAlt2,
                  },
                }}
                InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
              />
            </Box>
            <Box marginTop="15px" marginBottom="15px">
              <TextField
                // error={priceError}
                id="price"
                value={price}
                className={classes.textField}
                fullWidth
                required
                label="Prix de la conférence à la demande"
                type="number"
                variant="filled"
                onChange={handleChange("price")}
                // helperText={priceError ? "Le prix doit être supérieur ou égal à 0" : ''}
                InputProps={{
                  shrink: "true",
                  classes: {
                    root: classes.textFieldRoot,
                    focused: classes.textFieldRootAlt,
                    input: classes.textFieldRootAlt2,
                  },
                }}
                InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
              />
            </Box>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-evenly"
              flexDirection="row"
            >
              <Button style={{ height: "25px" }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    textTransform: "none",
                    color: "#000000",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Annuler
                </Typography>
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Box height="45vw">
        <DataGridPro
          rows={rows}
          rowHeight={50}
          pageSize={5}
          // selectionModel={selectionModelCycle}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

const DisplayCycles = ({
  selectionModelCycle,
  setSelectionModelCycle,
  myRef,
  setIsEventCycleForm,
  setIsEventCycleEdit,
  secondScreenHook,
  selectionModel,
  shouldUpdate,
  setShouldUpdate,
}) => {
  // rows definition
  // columns definition
  const data = useSelector((state) => state.dataReducer);
  const company = data.companies[0];
  const [cycles, setCycles] = useState([]);
  const [rows, setRows] = useState([]);
  // const cycles = useSelector(state => state.cyclesReducer)
  const events = useSelector((state) => state.eventsReducer);

  const getImage = async (id, ext) => {
    console.log(`${company.id}/cycles/S${id}.${ext}`);
    const url = await Storage.get(`${company.id}/cycles/S${id}.${ext}`, {
      level: "private",
      contentType: `image/${ext}`,
    });
    const response = await fetch(url);
    const blob = await response.blob();
    const res = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
    return res;
  };

  useEffect(() => {
    if (shouldUpdate) {
      API.graphql(
        graphqlOperation(cyclesbyOrganisateur, {
          organisateurId: company.id,
        })
      ).then((res) => {
        setCycles(res.data.cyclesbyOrganisateur.items);
      });
      fetchConferenceData();
      fetchCycleData();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    const getEvents = async (elem) => {
      return await API.graphql(
        graphqlOperation(eventsbyCycle, {
          cycleId: elem.id,
        })
      );
    };

    const getRows = async () => {
      const res = [];
      console.log(cycles);
      for (const value of cycles) {
        let nbAttending = 0;
        const resEvents = await getEvents(value);
        let cycleEvents = resEvents.data.eventsbyCycle.items;
        let state = "prêt";
        cycleEvents.forEach((event) => {
          if (!event.title1 || !event.imageKey) state = "incomplet";
        });
        const base64Image = await getImage(
          value.imageKey,
          value.imageETagOgImage
        );
        if (value.status === "PUBLISHED") state = "publié";
        res.push({
          id: value.id,
          status: state,
          title: value.title,
          prix: value.prix,
          image: base64Image,
          capacite: value.nbMax,
          nbEvents: cycleEvents.length,
          cycleEvents: cycleEvents,
        });
      }
      setRows(res);
    };
    getRows();
  }, [cycles]);

  useEffect(() => {
    API.graphql(
      graphqlOperation(cyclesbyOrganisateur, {
        organisateurId: company.id,
      })
    ).then((res) => {
      console.log(res.data.cyclesbyOrganisateur.items, company.id);
      setCycles(res.data.cyclesbyOrganisateur.items);
    });
    fetchConferenceData();
    fetchCycleData();
  }, []);

  if (!cycles || cycles.totalCount === 0) return null;

  // var rows = cycles.data.map((elem) => {
  // 	const cycleId = elem.id
  // 	const cycleEvents = events.data.filter(event => event.cycleId === cycleId)

  // 	let state = "prêt"
  // 	cycleEvents.forEach(event => {
  // 		if (!event.title1 || !event.imageKey)
  // 			state = "incomplet"
  // 	})
  // 	if (elem.status === "PUBLISHED")
  // 		state = "publié"
  // 	return ({
  // 		id: elem.id,
  // 		status: state,
  // 		title: elem.title,
  // 		prix: elem.prix,
  // 		image: elem.base64Image,
  // 		capacite: elem.nbMax,
  // 		nbEvents: cycleEvents.length,
  // 		cycleEvents: cycleEvents
  // 	})
  // })

  const updateRows = () => {
    if (cycles.data?.length === 0) {
      rows = [];
    }
    rows = cycles.data.map((elem) => {
      const cycleId = elem.id;
      const cycleEvents = events.data.filter(
        (event) => event.cycleId === cycleId
      );

      let state = "prêt";
      cycleEvents.forEach((event) => {
        if (!event.title1 || !event.imageKey) state = "incomplet";
      });
      if (elem.status === "PUBLISHED") state = "publié";
      return {
        id: elem.id,
        status: state,
        title: elem.title,
        prix: elem.prix,
        image: elem.base64Image,
        capacite: elem.nbMax,
        nbEvents: cycleEvents.length,
        cycleEvents: cycleEvents,
      };
    });
  };

  const publishSingleCycle = (id) => {
    updateCycleBatch({ id: id, status: "PUBLISHED" })
      .then(() => {
        setShouldUpdate(true);
      })
      .catch((err) => console.log(err));
  };

  const unpublishSingleCycle = (id) => {
    updateCycleBatch({ id: id, status: "UNPUBLISHED" })
      .then(() => {
        setShouldUpdate(true);
      })
      .catch((err) => console.log(err));
  };

  const deleteSingleCycle = (id) => {
    handleDeleteCycle({ id: id })
      .then(() => {
        setShouldUpdate(true);
        setSelectionModelCycle([]);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      field: "etat",
      width: 50,
      headerName: "Etat",
      renderHeader: () => <TableHeader header={"Etat"} />,
      renderCell: (params) => {
        const state = params.row.status;
        let color = red[500];
        let caption = "incomplet";
        let title =
          "Il manque des informations nécessaires à la publication de votre cycle";
        if (state === "publié") {
          color = green[300];
          caption = "en ligne";
          title =
            "Vous n'avez plus rien à faire, le cycle sera en ligne sous peu";
        }

        if (state === "prêt") {
          caption = "prêt";
          color = orange[200];
          title = "Le cycle est prêt à être publié";
        }

        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Box
              marginTop="10px"
              display="flex"
              flexDirection="column"
              alignContent="center"
              alignItems="center"
            >
              <LightTooltip title={title}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                >
                  <Dot color={color} />
                  <Box>
                    <Typography
                      style={{ fontSize: "0.5rem", marginTop: "2px" }}
                    >
                      {caption}
                    </Typography>
                  </Box>
                </Box>
              </LightTooltip>
            </Box>
          </div>
        );
      },
    },
    {
      field: "image",
      renderHeader: () => <TableHeader header={"Image"} />,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const image = params.row.image;

        return (
          <img
            alt=""
            style={{ width: "2.8vw", height: "2.8vw", objectFit: "cover" }}
            src={image}
          ></img>
        );
      },
    },

    {
      field: "nom",
      headerName: "Nom",
      width: 300,
      renderHeader: () => <TableHeader header={"Nom"} />,
      renderCell: (params) => {
        const state = params.row.title;
        return <div>{state}</div>;
      },
    },
    {
      field: "prix",
      width: 50,
      headerName: "Prix",
      renderHeader: () => <TableHeader header={"Prix"} />,
      cellClassName: "theme--cell",
    },
    {
      field: "nbEvents",
      width: 130,
      headerName: "Nb événements",
      renderHeader: () => <TableHeader header={"Nb événements"} />,
      cellClassName: "theme--cell",
    },
    {
      field: "modifiable",
      width: 80,
      headerName: "Modifiable",
      cellClassName: "theme--cell",
      renderHeader: () => <TableHeader header={"Modifiable"} />,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            justifyItems="center"
          >
            <LightTooltip title="événement totalement modifiable">
              <LockOpenIcon style={{ color: green[500] }} small="true" />
            </LightTooltip>
            {/* {params.value === true ? <LightTooltip title="événement totalement modifiable"><LockOpenIcon style={{ color: green[500] }} small="true" /></LightTooltip> : (<LightTooltip title="Vous avez au moins un inscrit. Seules des corrections minimes du texte sont autorisées"><LockIcon small="true" /></LightTooltip>)} */}
          </Box>
        );
      },
    },
    {
      field: "action",
      width: 65,
      renderHeader: () => <TableHeader header={"Modifier"} />,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          secondScreenHook[1]({
            modalId: "1-1",
            state: "modify",
            cycleId: params.row.id,
          });
          console.log("changed cycle hook", params.row.id);
          setIsEventCycleForm(false);
          setIsEventCycleEdit(true);
          // setIsClone(false)
          // setIsEventForm(true)
          // eventRef.current?.scrollIntoView({behavior: 'smooth'})
          // setSecondScreen({
          // 	modalId: "1-0",
          // 	state: "modify",
          // 	eventId: params.row.id
          // })
          // setConferenceId(params.row.id)
        };
        return (
          <Box
            width="40px"
            padding="5px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
          >
            <ColorButton onClick={onClick}>
              <EditIcon />
            </ColorButton>
          </Box>
        );
      },
    },
    {
      field: "delete",
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Supprimer"} />
        </Box>
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          deleteSingleCycle(params.row.id);
        };
        return (
          <Box
            width="40px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            justifyItems="center"
          >
            <ColorButton onClick={onClick}>
              <DeleteIcon />
            </ColorButton>
          </Box>
        );
      },
    },
    {
      field: "publier",
      renderHeader: () => (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          justifyItems="center"
        >
          <TableHeader header={"Action"} />
        </Box>
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const publishRow = () => {
          publishSingleCycle(params.row.id);
        };
        const unpublishRow = () => {
          unpublishSingleCycle(params.row.id);
        };
        return (
          <Box>
            {params.row.status === "prêt" || params.row.status === null ? (
              <StyledButtonTab onClick={publishRow}>Publier</StyledButtonTab>
            ) : params.row.status === "publié" ? (
              <StyledButtonTab onClick={unpublishRow}>
                Dépublier
              </StyledButtonTab>
            ) : null}
          </Box>
        );
      },
    },
  ];

  return (
    <Box width="100%">
      {cycles.totalCount !== 0 && (
        <Box height="45vw" width="100%">
          <DataGridPro
            ref={myRef}
            onSelectionModelChange={(selectionModel) => {
              setSelectionModelCycle(selectionModel);
            }}
            onRowSelected={(row) => {
              if (row.isSelected) {
                const ids = selectionModelCycle.map((row) => row.id);
                if (!ids.includes(row.data.id)) {
                  setSelectionModelCycle(selectionModelCycle.concat(row.data));
                }
              }
            }}
            disableSelectionOnClick
            // checkboxSelection
            rowHeight={50}
            localeText={localeText}
            onClick={() => {}}
            rows={rows}
            selectionModel={selectionModelCycle}
            columns={columns}
            pageSize={5}
          />
        </Box>
      )}
    </Box>
  );
};

const theme = createTheme(
  {
    palette: {
      primary: {
        main: red[400],
      },
      secondary: {
        main: blue[400],
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          "& h1": {
            color: colorBlue,
          },
          "& h2": {
            color: colorYellow,
          },
        },
        title: {
          "& h1": {
            color: colorBlue,
          },
          "& h2": {
            color: colorYellow,
          },
        },
      },
    },
    status: {
      danger: orange[500],
    },
  },
  frFR
);

const useStylesMain = makeStyles(
  (theme) => ({
    root: {
      width: "100%",

      "& .theme--cell": {
        fontSize: "0.8rem",
      },
      // '& .MuiCheckbox-colorPrimary': {
      //     color: "red",

      // }
    },
    title: {
      color: "#273370",
      fontSize: "1.5rem",
      marginLeft: "3vw",
      marginBottom: "2vw",
    },
    contentContainer: {
      display: "flex",
    },

    tableContainer: {},
    actionsContainer: {},
    action: {},
    button: {
      border: 0,
      borderRadius: 2,
      color: "white",
      width: "1vw",
      height: "2vw",
      padding: "0vw",
    },
  }),
  { index: 1 }
);

function getFullName(params) {
  return params.row.date;
}

const options = { year: "numeric", month: "numeric", day: "numeric" };

const DataTable = ({
  rows,
  columns,
  setSelectionModel,
  selectionModel,
  myRef,
  restrictDate,
}) => {
  const now = Date.now();
  const futureDateRows = rows.filter((row) => row.date > now);
  const templateRows = rows.filter((row) => row.date === "brouillon");
  const rowsChoices = [rows, futureDateRows, templateRows];
  const showRows = rowsChoices[restrictDate];

  return (
    <Box marginBottom="20px" width="100%">
      <div
        style={{
          justifyContent: "space-evenly",
          height: "30vw",
          width: "100%",
          backgroundColor: colorTaupeEncorePlusClair,
        }}
      >
        {/* <StyledEngineProvider injectFirst> */}
        <ThemeProvider theme={theme}>
          <DataGridPro
            ref={myRef}
            onSelectionModelChange={(newSelectionModel) => {
              console.log(newSelectionModel);
              setSelectionModel(newSelectionModel);
            }}
            disableSelectionOnClick
            checkboxSelection
            rowHeight={50}
            localeText={localeText}
            rows={showRows}
            columns={columns}
            selectionModel={selectionModel}
            pageSize={5}
          />
        </ThemeProvider>
        {/* </StyledEngineProvider> */}
      </div>
    </Box>
  );
};

const TableHeader = ({ header, restrictDate, setRestrictDate }) => {
  const isDate = header === "Date";
  const colors = [grey[300], orange[400], green[800]];
  const color = colors[restrictDate];

  return (
    <div
      style={{
        fontSize: "0.7rem",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      {isDate && (
        <Tooltip title="change le champs de sélection.">
          <IconButton
            onClick={() => setRestrictDate((restrictDate + 1) % 3)}
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <Adjust fontSize="small" sx={{ color: color }} />
          </IconButton>
        </Tooltip>
      )}
      {header}
    </div>
  );
};

const Dot = ({ color }) => {
  return (
    <Box
      width="15px"
      height="15px"
      position="relative"
      bottom="0px"
      zIndex={-10}
    >
      <svg viewBox="0 0 150 150">
        <circle cx={75} cy={75} r={60} fill={color} />
      </svg>
    </Box>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: grey[800],
    padding: "10px",
    color: "#F0F0F0",
    lineHeight: "1.3rem",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1a5aa3",
  color: "#FFFFFF",
  height: "32px",
  borderRadius: "20px",
  fontSize: "0.85rem",
  margin: "5px",
  marginTop: "20px",

  "&:hover": {
    backgroundColor: grey[700],
  },
  "&:disabled": {
    backgroundColor: grey[200],
  },
});

const StyledButtonTab = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1a5aa3",
  color: "#FFFFFF",
  height: "32px",
  borderRadius: "20px",
  fontSize: "0.85rem",

  "&:hover": {
    backgroundColor: grey[700],
  },
  "&:disabled": {
    backgroundColor: grey[200],
  },
});
