import { Box, Button, styled, TextField, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { updateFormateur } from "../../graphql/mutations";
import { ImageUploadButton } from "../general";
import { handleClose00 } from "../handleClose";
import ImageUpload from "../helpers/imageUpload";
import useStyles from "../style/textFields";
import { getFormateur } from "../../graphql/queries";
import { useSelector } from "react-redux";

const nbMaxLettersIntro = 500;

const FicheForm = (props) => {
  const { setOpen, companyAccountId, fetchData, ficheId } = props;
  const [formData, setFormData] = useState({});
  const data = useSelector((state) => state.dataReducer);
  const [valid, setValid] = useState(true);
  const [selectFile, setSelectFile] = useState(null);
  const classes = useStyles(props);

  const company = props.company;

  useEffect(() => {
    if (ficheId !== "") {
      API.graphql(graphqlOperation(getFormateur, { id: ficheId }))
        .then((res) => {
          const formateur = res.data.getFormateur;
          setFormData(formateur);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChange = (key) => {
    return (e) => {
      const value = e.target.value;
      const target = {
        ...formData,
        [key]: value,
      };
      setFormData(target);
    };
  };

  const isValid = () => {
    return (
      formData.prenom && formData.nom && formData.imageStore && formData.intro
    );
  };

  const saveFiche = () => {
    if (!isValid()) {
      setValid(false);
      return;
    }
    const tmpData = {
      hashList: formData.hashList,
      prenom: formData.prenom,
      id: ficheId !== "" ? ficheId : null,
      nom: formData.nom,
      linkedin: formData.linkedin,
      intro: formData.intro,
      lemonwayId: company.lemonwayId,
      companyAccountId: companyAccountId,
      imageStore: formData.imageStore,
    };
    // const tmpData = {...formData, companyAccountId: companyAccountId}
    setValid(true);
    handleClose00(tmpData, fetchData, ficheId ? false : true);
    setOpen(false);
  };

  const getNbLetters = (field) => {
    if ((formData && formData[field]) || (formData && formData[field] === "")) {
      const text = formData[field];
      if (!text || text === "") return 0;
      return text.length;
    }
    return 0;
  };

  return (
    <Box margin="20px">
      <Box>
        <Box display="flex" flexDirection="row">
          <Box
            padding="1vw"
            paddingTop="2vw"
            flex={2}
            borderBottom="1px solid"
            bgcolor={blueGrey[100]}
          >
            <div className={classes.modalTitle}>Votre profil intervenant</div>
          </Box>
          <Box display="flex" height="18vh" bgcolor="#fff">
            <ImageUploadButton setSelectFile={setSelectFile} />
            {/* <ImageUpload data={data} tempImageStore={formData?.imageStore ? formData.imageStore : data.imageStore} setTempImageStore={(x) => setFormData({ ...formData, imageStore: x })} selectFile={selectFile} /> */}
            <ImageUpload
              data={{}}
              tempImageStore={formData?.imageStore ? formData.imageStore : {}}
              setTempImageStore={(x) =>
                setFormData({ ...formData, imageStore: x })
              }
              selectFile={selectFile}
            />
          </Box>
        </Box>
        <form className={classes.modal00root} noValidate autoComplete="off">
          <Box flexDirection="column" marginTop="1vw" display="flex">
            <Box flexDirection="row" display="flex" alignItems="start">
              <TextField
                label="Prénom:"
                variant="filled"
                value={formData && formData.prenom ? formData.prenom : ""}
                onChange={handleChange("prenom")}
                style={{ marginRight: "20px", width: "300px" }}
              />
              <TextField
                label="Nom:"
                variant="filled"
                value={formData && formData.nom ? formData.nom : ""}
                onChange={handleChange("nom")}
                style={{ marginRight: "20px", width: "300px" }}
              />
            </Box>
            <Box
              marginTop="1vw"
              width="43vw"
              display="flex"
              flexDirection="column"
            >
              <TextField
                variant="filled"
                title="Décrivez vous de manière brève, en vous limitant à 250 caractères"
                label="Courte introduction :"
                value={formData && formData.intro ? formData.intro : ""}
                multiline
                rows={3}
                maxRows={3}
                onChange={handleChange("intro")}
                InputProps={{
                  shrink: "true",
                  classes: {
                    root: classes.textFieldRoot,
                    focused: classes.textFieldRootAlt,
                    input: classes.textFieldRootAlt2,
                  },
                }}
                InputLabelProps={{ classes: { root: classes.textFieldLabel } }}
                SelectProps={{ classes: { root: classes.selectProps } }}
                onInput={(e) => {
                  e.target.value = e.target.value.substring(
                    0,
                    nbMaxLettersIntro
                  );
                }}
              />
              <Box
                position="relative"
                bottom="0.10vw"
                color="gray"
                fontSize="0.7rem"
                textAlign="right"
              >
                {getNbLetters("intro") + "/" + nbMaxLettersIntro}
              </Box>
            </Box>
          </Box>
          <br />
        </form>
        {valid ? null : (
          <Typography style={{ color: "red", marginBottom: "10px" }}>
            Le profil n'est pas complet
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="180px"
        >
          <StyledButton
            onClick={() => {
              saveFiche();
            }}
          >
            Sauvegarder
          </StyledButton>
          <StyledButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Annuler
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FicheForm;

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1a5aa3",
  color: "#FFFFFF",
  alignSelf: "center",
  // height: '32px',
  borderRadius: "20px",
  fontSize: "0.85rem",
  "&:hover": {
    backgroundColor: "#404040",
  },
});
