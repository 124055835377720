import hash from "object-hash";
import { v4 as uuid_v4 } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import {
  updateFormateur,
  createPublication,
  createFormateur,
  updateCompanyAccount,
} from "../graphql/mutations";
import { Storage } from "aws-amplify";
import {
  saveNewEventBatch,
  saveNewCycleBatch,
  updateEventBatch,
  uploadImageBatch,
  uploadImageBatchV2,
  HandleDeleteEvent,
  updateCycleBatch,
} from "./helpers/batches";
import store from "../redux/store";
import imageCompression from "browser-image-compression";
import {
  fetchPublicationsData,
  fetchConferenceData,
  fetchCycleData,
} from "./helpers/fetchers";
import { frontBuild } from "./helpers/lambda";
import { nullConference } from "./general";
import { eventCategories } from "./helpers/events";
import { Auth } from "aws-amplify";
import { isValidMeetUrl } from "../helpers/syntax";
import cleanBeforeSave from "../components/SVGIcons/helpers";
import { Buffer } from "buffer";

export const handleClose02 = (formData, fetchData) => {
  const data = store.getState().dataReducer;
  const user = {
    attributes: {
      sub: data.cognitoId,
    },
  };
  const organismId = formData.id;

  const tempImageStore = formData.imageStore;
  const regex = /^data:image\/(.+);base64/;
  let imageData = null;
  let type = null;

  if (tempImageStore) {
    imageData = {
      pixelCropWidth: parseInt(tempImageStore.cropData.width),
      pixelCropHeight: parseInt(tempImageStore.cropData.height),
      pixelCropX: parseInt(tempImageStore.cropData.x),
      pixelCropY: parseInt(tempImageStore.cropData.y),
    };
    type = regex.exec(tempImageStore.data)[1];
  }

  let input = {};
  if (tempImageStore) {
    input = {
      ...formData,
      imageExt: type,
      imageId: tempImageStore.imageId,
    };
    delete input.imageStore;
  } else {
    input = {
      ...formData,
    };
  }

  saveResultS4(tempImageStore, input, fetchData, user);
};

export const handleClose00 = (storeForm, fetchData, create) => {
  const data = store.getState().dataReducer;

  // can simplify the code: only information needed is cognitoId
  const user = {
    attributes: {
      sub: data.cognitoId,
    },
  };

  const modalStore = storeForm;
  const tempImageStore = modalStore.imageStore;

  //const cognitoId = user.attributes.sub

  let imageData = null;

  // Regex definition, used to check the validity of the linkedin urls

  const linkedinRegexHandlers = [
    {
      regex: /(((?:https?:)?\/\/)?www.linkedin.com\/in\/(.+)\/)/,
      type: "youtube",
    },
  ];

  // variables that will be filled with the Video and Linkedin information
  let linkedin = null;

  // handling of the linkedin field
  if (modalStore.linkedin) {
    const handler = linkedinRegexHandlers.find((handler) =>
      modalStore.linkedin.match(handler.regex)
    );
    if (handler) {
      const regexSearch = modalStore.linkedin.match(handler.regex);
      linkedin = regexSearch[0];
    }
  }

  // preparing data in case user has selected a new image
  if (tempImageStore) {
    imageData = {
      pixelCropWidth: parseInt(tempImageStore.cropData.width),
      pixelCropHeight: parseInt(tempImageStore.cropData.height),
      pixelCropX: parseInt(tempImageStore.cropData.x),
      pixelCropY: parseInt(tempImageStore.cropData.y),
    };
  }

  // [A] handling case: user has already uploaded personnal information in the cloud
  //
  let input = {
    ...modalStore,
    ...imageData,
  };

  if (create === false) {
    input = { ...input, id: modalStore.id ? modalStore.id : data.id };
  }

  // const input = {
  //   id: data.id,
  //   ...modalStore,
  //   ...imageData
  // }

  delete input.imageStore;

  // TBM: needs to handle different size of images
  if (create == true) {
    saveResultS2(tempImageStore, input, fetchData, user);
  } else {
    saveResultS3(tempImageStore, input, fetchData, user);
  }

  if (data.state === "published") frontBuild();
};

export const handleCloseOrganism10 = (
  dataStore,
  setData,
  secondScreenHook,
  refreshConferences
) => {
  const eventsData = store.getState().eventsReducer;
  let eventId = null;
  let event = null;

  if (dataStore != null) {
    const data = store.getState().dataReducer;
    const state = secondScreenHook[0]?.state;

    if (state != "create") {
      eventId = secondScreenHook[0].eventId;
      event = eventsData.data.find((event) => event.id === eventId);
    }
    const {
      title1,
      title2,
      format,
      outil,
      nbMax,
      duree,
      outilUrl,
      date,
      documentHtml,
      prix,
      langue,
      checked,
      niveau,
      categories,
      organisateurId,
      formateurId,
      futurReplay,
    } = dataStore;
    const extractedOutilUrl = isValidMeetUrl(outilUrl)
      ? "https://" + isValidMeetUrl(outilUrl)
      : null;
    let newHTML;

    if (documentHtml) {
      newHTML = cleanBeforeSave(documentHtml); // Assume xmlText contains the example XML
    }
    const catagoriesArray = categories ? categories.split("|") : [];
    let binaryFilter = "";
    eventCategories.forEach((category) => {
      if (catagoriesArray.includes(category)) binaryFilter = binaryFilter + "1";
      else binaryFilter = binaryFilter + "0";
    });
    binaryFilter = binaryFilter + "0".repeat(16 - binaryFilter.length);
    const categoryCode = parseInt(binaryFilter, 2);
    const address = dataStore.address?.label;
    const newData = {
      title1: title1 ? title1 : "",
      title2: title2 ? title2 : "",
      format: format ? format : nullConference().format,
      outil: outil ? outil : nullConference().outil,
      duree: duree ? duree : nullConference().duree,
      outilUrl: extractedOutilUrl
        ? extractedOutilUrl
        : nullConference().outilUrl,
      nbMax: nbMax ? nbMax : nullConference().nbMax,
      date: date,
      documentHtml: newHTML ? newHTML : nullConference().documentHtml,
      organisateurId: organisateurId ? organisateurId : "",
      futurReplay: futurReplay ? futurReplay : false,
      formateurId: formateurId ? formateurId : "",
      prix: prix >= 0 ? prix : nullConference().prix,
      langue: langue ? langue : nullConference().langue,
      niveau: niveau ? niveau : nullConference().niveau,
      categoriesCode: categoryCode
        ? categoryCode
        : nullConference().categoriesCode,
      categories: categories ? categories : nullConference().categories,
      address: address ? address : null,
    };

    const modelId = uuid_v4();
    const imageKey = uuid_v4();
    //const newData = dataHook[0]
    const imageStore = dataStore.imageStore;
    imageStore && delete newData.imageStore;
    const regex = /^data:image\/(.+);base64/;
    if (imageStore) {
      const type = regex.exec(imageStore.original)[1];
      newData.imageKey = imageKey;
      newData.imageETagOgImage = type;
    } else newData.imageKey = event?.imageKey;
    // if (imageStore && state != "clone") newData.imageKey = imageKey;
    // else newData.imageKey = event?.imageKey;
    newData.isLinkedToModel = true;
    newData.date = checked || !date ? 0 : parseInt(date.getTime() / 1000);
    delete newData.checked;
    let batch3 = null;

    if (state === "modify")
      batch3 = updateEventBatch({ ...newData, id: eventId });
    else batch3 = saveNewEventBatch({ ...newData });

    const batch5 = uploadImageBatch(imageStore, formateurId, imageKey);
    let newEventId = null;
    batch3
      .then(({ data }) => {
        if (data.createEvent) newEventId = data.createEvent.id;
        else newEventId = data.updateEvent.id;
        return updateEventBatch({ id: newEventId });
      })
      .then(() =>
        dataStore.imageStore != null ? batch5 : fetchConferenceData()
      )
      .then(() => {
        refreshConferences();
      })
      .catch((err) => console.log(err));
  }

  if (event?.state === "published") frontBuild();
  setData(null);
  secondScreenHook[1](null);
};

export const handleClose10 = (
  dataStore,
  setData,
  secondScreenHook,
  getRows
) => {
  const eventsData = store.getState().eventsReducer;
  let eventId = null;
  let event = null;

  if (dataStore != null) {
    const data = store.getState().dataReducer;
    const state = secondScreenHook[0]?.state;

    if (state != "create") {
      eventId = secondScreenHook[0].eventId;
      event = eventsData.data.find((event) => event.id === eventId);
    }

    const {
      title1,
      title2,
      format,
      outil,
      nbMax,
      duree,
      outilUrl,
      date,
      documentHtml,
      prix,
      langue,
      checked,
      niveau,
      categories,
      futurReplay,
    } = dataStore;

    const extractedOutilUrl = isValidMeetUrl(outilUrl)
      ? "https://" + isValidMeetUrl(outilUrl)
      : null;

    let newHTML;
    if (documentHtml) newHTML = cleanBeforeSave(documentHtml); // Assume xmlText contains the example XML

    const catagoriesArray = categories ? categories.split("|") : [];
    let binaryFilter = "";
    eventCategories.forEach((category) => {
      if (catagoriesArray.includes(category)) binaryFilter = binaryFilter + "1";
      else binaryFilter = binaryFilter + "0";
    });

    binaryFilter = binaryFilter + "0".repeat(16 - binaryFilter.length);

    const categoryCode = parseInt(binaryFilter, 2);

    const address = dataStore.address?.label;

    const newData = {
      title1: title1 ? title1 : "",
      title2: title2 ? title2 : "",
      format: format ? format : nullConference().format,
      outil: outil ? outil : nullConference().outil,
      duree: duree ? duree : nullConference().duree,
      outilUrl: extractedOutilUrl
        ? extractedOutilUrl
        : nullConference().outilUrl,
      nbMax: nbMax ? nbMax : nullConference().nbMax,
      date: date,
      documentHtml: newHTML ? newHTML : nullConference().documentHtml,
      prix: prix >= 0 ? prix : nullConference().prix,
      langue: langue ? langue : nullConference().langue,
      niveau: niveau ? niveau : nullConference().niveau,
      categoriesCode: categoryCode
        ? categoryCode
        : nullConference().categoriesCode,
      categories: categories ? categories : nullConference().categories,
      address: address ? address : null,
      futurReplay: futurReplay ? futurReplay : false,
    };

    const modelId = uuid_v4();
    const imageKey = uuid_v4();

    //const newData = dataHook[0]
    const imageStore = dataStore.imageStore;
    imageStore && delete newData.imageStore;
    const regex = /^data:image\/(.+);base64/;
    if (imageStore) {
      const type = regex.exec(imageStore.original)[1];
      newData.imageKey = imageKey;
      newData.imageETagOgImage = type;
    } else newData.imageKey = event?.imageKey;
    newData.isLinkedToModel = true;
    newData.date = checked || !date ? 0 : parseInt(date.getTime() / 1000);
    delete newData.checked;

    let batch3 = null;
    if (state === "modify")
      batch3 = updateEventBatch({ ...newData, id: eventId });
    else {
      batch3 = saveNewEventBatch({ ...newData, formateurId: data.id });
    }

    const batch5 = uploadImageBatch(imageStore, data.id, imageKey);
    let newEventId = null;

    batch3
      .then(({ data }) => {
        if (data.createEvent) newEventId = data.createEvent.id;
        else newEventId = data.updateEvent.id;

        return updateEventBatch({ id: newEventId });
      })

      .then(() =>
        dataStore.imageStore != null ? batch5 : fetchConferenceData()
      )
      .then(() => {
        fetchConferenceData();
        if (getRows) {
          getRows();
        }
      })
      .catch((err) => console.log(err));
  }

  if (event?.state === "published") frontBuild();

  setData(null);
  secondScreenHook[1](null);
};

// DEBUG START CODE
export const handleClose11 = (
  dataStore,
  setData,
  secondScreenHook,
  updateRows,
  selectedEvents,
  setShouldUpdate
) => {
  const baseBatchEvent = (index, id, nbReserved, cycleId) => {
    return `mutation${index} : updateEvent(input: {id: "${id}", cycleId: "${cycleId}",nbReserved: ${nbReserved}}) {
      nbReserved
    }`;
  };

  const baseBatchCycle = (id, status) => {
    return `mutationCycle : updateCycle(input: {id: "${id}", status: ${status}}) {
      id
    }`;
  };

  const batchMutationMaker = (cycleId, eventsIds, nbReserved, isCreation) => {
    let innerMutation = baseBatchCycle(
      cycleId,
      isCreation ? "CREATED" : "UPDATED"
    );

    eventsIds.forEach((id, index) => {
      innerMutation =
        innerMutation + baseBatchEvent(index, id, nbReserved, cycleId);
    });

    return `mutation batchMutation {${innerMutation}}`;
  };

  if (dataStore != null) {
    const data = store.getState().dataReducer;
    const state = secondScreenHook[0].state;
    const eventsStore = store.getState().eventsReducer;
    const cyclesStore = store.getState().cyclesReducer;
    const selectedIds = eventsStore.selectedRows;
    let cycleId = null;
    let cycle = null;

    if (state == "modify") {
      cycleId = secondScreenHook[0].cycleId;
      cycle = cyclesStore.data.find((cycle) => cycle.id === cycleId);
    }

    let events = [];
    if (!selectedEvents) {
      events = eventsStore.data.filter((event) =>
        selectedIds.includes(event.id)
      );
    } else {
      events = selectedEvents;
    }

    const availablePlaces = events.map((event) => {
      let attendingNb = 0;
      if (typeof event?.attendingNb === "number")
        attendingNb = event.attendingNb;

      let nbReserved = 0;
      if (typeof event?.nbReserved === "number") nbReserved = event.nbReserved;

      return event.nbMax - attendingNb - nbReserved;
    });
    const availableRoom = Math.min.apply(Math, availablePlaces);

    const { title, prix, documentHtml } = dataStore;

    const newData = {
      title: title ? title : "",
      prix: prix ? prix : 0,
      nbMax: availableRoom,
      status: "INITIATED",
      documentHtml: documentHtml ? documentHtml : "",
    };

    const imageKey = uuid_v4();

    const imageStore = dataStore.imageStore;
    const regex = /^data:image\/(.+);base64/;
    if (imageStore) {
      const type = regex.exec(imageStore.original)[1];
      newData.imageKey = imageKey;
      newData.imageETagOgImage = type;
    }

    let batch3 = null;
    if (state == "modify") {
      batch3 = updateCycleBatch({ ...newData, id: cycleId });
    } else {
      if (dataStore.organisateurId) {
        batch3 = saveNewCycleBatch({
          ...newData,
          organisateurId: dataStore.organisateurId,
        });
      } else {
        batch3 = saveNewCycleBatch({ ...newData, formateurId: data.id });
      }
    }
    console.log(newData, imageStore);
    const batch5 = uploadImageBatch(
      imageStore,
      dataStore.organisateurId ? dataStore.organisateurId : data.id,
      imageKey,
      "cycles"
    );

    let isCreation = false;

    batch3
      .then(({ data }) => {
        if (data.createCycle) {
          cycleId = data.createCycle.id;
          isCreation = true;
        } else {
          cycleId = data.updateCycle.id;
        }
        const batchMutation = batchMutationMaker(
          cycleId,
          events.map((event) => event.id),
          availableRoom,
          isCreation
        );

        API.graphql(graphqlOperation(batchMutation), {})
          .then(() => {
            fetchConferenceData();
            fetchCycleData();
            updateRows();
            if (setShouldUpdate) {
              setShouldUpdate(true);
            }
          })
          .catch((err) => console.log(err));

        return true;
      })
      .then(() =>
        dataStore.imageStore != null ? batch5 : fetchConferenceData()
      )
      .then(() => {
        fetchConferenceData();
        fetchCycleData();
        updateRows();
        if (setShouldUpdate) {
          setShouldUpdate(true);
        }
      })
      .catch((err) => console.log(err));

    setData(null);
    secondScreenHook[1](null);
  }
};

/// general functions / variables

const saveResultS2 = (imageTempData, input, fetchData, user) => {
  API.graphql(graphqlOperation(createFormateur, { input: input }))
    .then((result) => {
      const { data: DBData } = result;
      const id = DBData.createFormateur.id;
      const regex = /^data:image\/(.+);base64/;

      if (imageTempData) {
        const type = regex.exec(imageTempData.data)[1];
        const imageData = imageTempData.data.replace(
          /^data:image\/\w+;base64,/,
          ""
        );
        const buffer = Buffer.from(imageData, "base64");
        const str = "Base64 Encoding in Node.js";
        const buff = Buffer.from(str, "utf-8");
        const previousHash = DBData.createFormateur.photoHash;
        const newHash = hash(imageTempData.data);

        if (previousHash != newHash) {
          Storage.put(
            `${id}/formateur/${imageTempData.imageId}.${type}`,
            buffer,
            { level: "private", contentType: `image/${type}` }
          ).then((result) => {
            const credentials = Auth.currentUserCredentials().then(
              (authData) => {
                void result;
                const { x, y, width, height } = imageTempData.cropData;
                const userId = result.key.split("/")[0];
                const key = result.key.split("/")[2];

                if (authData.identityId) {
                  const apiKeys = {
                    topLeftX: x,
                    topLeftY: y,
                    bottomRightX: x + width,
                    bottomRightY: y + height,
                    identityId: authData.identityId,
                    userId: userId,
                    key: key,
                  };
                  const data = store.getState().dataReducer;

                  API.get("imageUploaderNew", "/upload", {
                    queryStringParameters: apiKeys,
                  })
                    .then((data) => {
                      return fetchData(user);
                    })
                    .catch((err) => console.log(err));
                }
                const secondInput = {
                  id: id,
                  imageId: imageTempData.imageId,
                  hasPhoto: true,
                };

                API.graphql(
                  graphqlOperation(updateFormateur, { input: secondInput })
                )
                  .then(() => {
                    return fetchData(user);
                  })
                  .catch(() => {});
              }
            );
          });
        }
      } else {
        fetchData(user);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const saveResultS4 = (imageTempData, input, fetchData, user) => {
  API.graphql(graphqlOperation(updateCompanyAccount, { input: input }))
    .then((res) => {
      const { data: DBData } = res;
      const id = DBData.updateCompanyAccount.id;
      const regex = /^data:image\/(.+);base64/;

      if (imageTempData) {
        const type = regex.exec(imageTempData.data)[1];
        const imageData = imageTempData.data.replace(
          /^data:image\/\w+;base64,/,
          ""
        );
        const buffer = Buffer.from(imageData, "base64");
        const str = "Base 64 Encoding in Node.js";
        const buff = Buffer.from(str, "utf-8");
        const previousHash = DBData.updateCompanyAccount.photoHash;
        const newHash = hash(imageTempData.data);

        if (previousHash !== newHash) {
          Storage.put(
            `${id}/company/${imageTempData.imageId}.${type}`,
            buffer,
            { level: "private", contentType: `image/${type}` }
          ).then((result) => {
            Auth.currentUserCredentials().then((authData) => {
              void result;
              const { x, y, width, height } = imageTempData.cropData;
              const userId = result.key.split("/")[0];
              const key = result.key.split("/")[2];
              if (authData.identityId) {
                const apiKeys = {
                  topLeftX: x,
                  topLeftY: y,
                  bottomRightX: x + width,
                  bottomRightY: y + height,
                  identityId: authData.identityId,
                  userId: userId,
                  key: key,
                };
                const data = store.getState().dataReducer;

                API.get("imageUploaderNew", "/upload", {
                  queryStringParameters: apiKeys,
                })
                  .then((data) => {
                    return fetchData();
                  })
                  .catch((err) => console.log(err));
              }
              const secondInput = {
                id: input.id,
                imageId: imageTempData.imageId,
              };

              API.graphql(
                graphqlOperation(updateCompanyAccount, { input: secondInput })
              )
                .then((res) => {
                  return fetchData();
                })
                .catch((err) => console.log(err));
            });
          });
        }
      } else {
        fetchData();
      }
    })
    .catch((err) => console.log(err));
};

const saveResultS3 = (imageTempData, input, fetchData, user) => {
  API.graphql(graphqlOperation(updateFormateur, { input: input }))
    .then((result) => {
      const { data: DBData } = result;
      const id = DBData.updateFormateur.id;
      const regex = /^data:image\/(.+);base64/;

      if (imageTempData) {
        const type = regex.exec(imageTempData.data)[1];

        const imageData = imageTempData.data.replace(
          /^data:image\/\w+;base64,/,
          ""
        );

        const buffer = Buffer.from(imageData, "base64");
        const str = "Base64 Encoding in Node.js";
        const buff = Buffer.from(str, "utf-8");

        const previousHash = DBData.updateFormateur.photoHash;
        const newHash = hash(imageTempData.data);

        if (previousHash != newHash) {
          Storage.put(
            `${id}/formateur/${imageTempData.imageId}.${type}`,
            buffer,
            { level: "private", contentType: `image/${type}` }
          ).then((result) => {
            const credentials = Auth.currentUserCredentials().then(
              (authData) => {
                void result;
                const { x, y, width, height } = imageTempData.cropData;
                const userId = result.key.split("/")[0];
                const key = result.key.split("/")[2];

                if (authData.identityId) {
                  const apiKeys = {
                    topLeftX: x,
                    topLeftY: y,
                    bottomRightX: x + width,
                    bottomRightY: y + height,
                    identityId: authData.identityId,
                    userId: userId,
                    key: key,
                  };

                  const data = store.getState().dataReducer;

                  API.get("imageUploaderNew", "/upload", {
                    queryStringParameters: apiKeys,
                  })
                    .then((data) => {
                      return fetchData(user);
                    })
                    .catch((err) => console.log(err));
                }

                const secondInput = {
                  id: input.id,
                  imageId: imageTempData.imageId,
                  hasPhoto: true,
                };

                API.graphql(
                  graphqlOperation(updateFormateur, { input: secondInput })
                )
                  .then(() => {
                    return fetchData(user);
                  })
                  .catch(() => {});
              }
            );
          });
        }
      } else {
        fetchData(user);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const handleClose01 = (storePubli, setStorePubli, fetchData) => {
  const data = store.getState().dataReducer;
  const organisateurId = storePubli.organisateurId;
  const salonId = storePubli.salonId;
  const formateurId = data.id;

  // can simplify the code: only information needed is cognitoId
  const user = {
    attributes: {
      sub: data.cognitoId,
    },
  };

  const publicationList = JSON.parse(
    store.getState().dataReducer?.publicationList
  );

  const publications = store.getState().publicationsReducer;
  const modalStore = storePubli;
  const setModalStore = setStorePubli;

  if (
    (formateurId || organisateurId) &&
    modalStore.type &&
    publications.loading === "false"
  ) {
    const newIndex = publications.indexMax >= 0 ? publications.indexMax + 1 : 0;

    switch (modalStore.type) {
      case "opengraph":
        const ogPublicationData = {
          formateurId: formateurId,
          organisateurId: organisateurId,
          salonId: salonId,
          type: "OPENGRAPH",
          url: modalStore.url,
          ogTitle: modalStore.ogTitle,
          ogImage: modalStore.ogImage,
          index: newIndex,
          activityType: modalStore.activityType
            ? modalStore.activityType
            : null,
        };
        DBcreatePublication(ogPublicationData);

        break;
      case "image":
        const key = uuid_v4();
        const imageData = {
          file: modalStore.blob,
          key: key,
          userId: organisateurId ? organisateurId : formateurId,
          path: "publications",
        };
        compressAndSaveImage(imageData);

        const publicationData = {
          formateurId: formateurId,
          organisateurId: organisateurId,
          salonId: salonId,
          type: "IMAGE",
          imageId: key,
          url: modalStore.url,
          index: newIndex,
          imageExt: modalStore.blob.name.split(".").pop(),
          imageTitle: modalStore.title
            ? modalStore.title
            : modalStore.imageTitle
            ? modalStore.imageTitle
            : "",
          activityType: modalStore.activityType
            ? modalStore.activityType
            : null,
        };
        DBcreatePublication(publicationData);

        break;
    }

    const data = store.getState().dataReducer;
    const newPublicationList = publicationList.concat(newIndex);
    DBupdateFormateur(
      { id: data.id, publicationList: JSON.stringify(newPublicationList) },
      user,
      fetchData
    );
  }

  setModalStore(null);
  if (data.state === "published") frontBuild();
};

export const handleClose00b = (descData, setDescFormData, fetchData) => {
  const data = store.getState().dataReducer;
  const formateurId = data.id;

  let newHTML;
  if (descData.documentHtml) newHTML = cleanBeforeSave(descData.documentHtml); // Assume xmlText contains the example XML

  // can simplify the code: only information needed is cognitoId
  const user = {
    attributes: {
      sub: data.cognitoId,
    },
  };

  const modalStore = newHTML
    ? { ...descData, documentHtml: newHTML }
    : descData;

  const setModalStore = setDescFormData;

  if (formateurId && modalStore) {
    const data = store.getState().dataReducer;
    DBupdateFormateur({ ...modalStore, id: data.id }, user, fetchData);
  }

  setModalStore(null);
  if (data.state === "published") frontBuild();
};

const DBcreatePublication = (props) => {
  API.graphql(graphqlOperation(createPublication, { input: props }))
    .then((data) => {
      return fetchPublicationsData({ id: props.formateurId });
    })
    .catch((err) => console.log(err));
};

export const DBupdateFormateur = (data, userData, fetchData) => {
  API.graphql(graphqlOperation(updateFormateur, { input: data }))
    .then(() => {
      return fetchData(userData);
    })
    .catch((data) => {
      console.log(data);
    });
};

const compressAndSaveImage = async (props) => {
  const { file, userId, key, path } = props;

  let compressionBatches = [];

  compressionSet.forEach((set) => {
    const miniBatch = imageCompression(file, set.options).then((blob) =>
      uploadImageBatchV2(blob, set.prefix, userId, key, path)
    );
    compressionBatches.push(miniBatch);
  });

  await Promise.all(compressionBatches);
  // .then(
  //   values => console.log(values)
  // )
  // .catch(err => console.log(err))
};

const compressionSet = [
  {
    name: "compressedS",
    prefix: "S",
    options: {
      maxSizeMB: 0.02,
      maxWidthOrHeight: 128,
      useWebWorker: true,
    },
  },
  {
    name: "compressedL",
    prefix: "L",
    options: {
      maxSizeMB: 300,
      maxWidthOrHeight: 512,
      useWebWorker: true,
    },
  },
];

// const getCoord = async (address) => {
//   const result = await geocodeByAddress(address)
//   const coords = await getLatLng(result[0])
//   return result

// }
