import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { colorGray } from "../../AppStyle";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CompanyInfo from "./companyInfo";
import CompanyForm from "./companyForm";
import CompanyDescForm from "./companyDescForm";
import CompanyDescInfo from "./companyDescInfo";
import { handleClose00, handleClose01, handleClose02 } from "../handleClose";
import { localeText, regexList, cleanText, cutText } from "../general";
import store from "../../redux/store";
import { insertPublication } from "../../features/data/dataSlice";
import { useConfirm } from "material-ui-confirm";
import { ColorButton } from "../BO-style";
import DeleteIcon from "@mui/icons-material/Delete";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getCompanyAccount, listPublications } from "../../graphql/queries";
import { useSelector } from "react-redux";
import CompanyPublicationForm from "./companyPublicationForm";
import { deletePublication } from "../../graphql/mutations";
import ProfileAdvancement from "../profileAdvancement";

const getSource = (url) => {
  const regex = regexList.find((regex) => url.match(regex));
  return url.match(regex)[2];
};

const SectionCompany = ({ fetchData, user }) => {
  const data = useSelector((state) => state.dataReducer);

  const [isCompanyForm, setIsCompanyForm] = useState(false);
  const [isCompanyHistoryForm, setIsCompanyHistoryForm] = useState(false);
  const [isCompanyPublicationForm, setIsCompanyPublicationForm] =
    useState(false);
  const [companyData, setCompanyData] = useState({});
  const [formData, setFormData] = useState(null);
  const [descFormData, setDescFormData] = useState(null);
  const [publiFormData, setPubliFormData] = useState(null);
  const [dragOrigin, setDragOrigin] = useState(null);
  const [rows, setRows] = useState([]);
  const profileRef = useRef(null);
  const historyRef = useRef(null);
  const publiRef = useRef(null);
  const confirm = useConfirm();

  const fetchRows = async () => {
    const tmpRows = [];
    const res = await API.graphql(
      graphqlOperation(listPublications, {
        filter: { organisateurId: { eq: data.companies[0].id } },
      })
    );
    for (const elem of res.data.listPublications.items) {
      const obj = {
        id: elem.id,
        url:
          elem.type === "IMAGE"
            ? await getBase64Image(elem.imageId, elem.imageExt)
            : elem.ogImage,
        title:
          elem.type === "IMAGE"
            ? elem.imageTitle
            : cutText(cleanText(elem.ogTitle), 80),
        source:
          elem.type === "IMAGE"
            ? elem.url
              ? elem.url
              : "formateur"
            : getSource(elem.url),
      };
      tmpRows.push(obj);
    }
    setRows(tmpRows);
  };

  const fetchInfo = async () => {
    if (data) {
      API.graphql(
        graphqlOperation(getCompanyAccount, { id: data.companies[0].id })
      )
        .then((res) => {
          setCompanyData(res.data.getCompanyAccount);
        })
        .catch((err) => console.log(err));
    }
  };

  const getBase64Image = async (key, ext) => {
    const url = await Storage.get(
      `${data.companies[0].id}/publications/S${key}.${ext}`,
      { level: "private", contentType: `image/${ext}` }
    );
    const response = await fetch(url);
    const blob = await response.blob();
    const res = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
    return res;
  };

  useEffect(() => {
    if (data) {
      fetchRows();
      fetchInfo();
    }
  }, [data]);

  const clickHandlerForm = () => {
    if (isCompanyForm) {
      handleClose02(formData, fetchInfo);
    }
    setIsCompanyForm(!isCompanyForm);
  };

  const clickHandlerFormCancel = () => {
    setIsCompanyForm(!isCompanyForm);
  };

  const clickHandlerHistoryForm = () => {
    if (isCompanyHistoryForm) {
      handleClose02(descFormData, fetchInfo);
    }
    setIsCompanyHistoryForm(!isCompanyHistoryForm);
  };

  const clickHandlerHistoryFormCancel = () => {
    setIsCompanyHistoryForm(!isCompanyHistoryForm);
  };

  const clickHandlerPublicationForm = () => {
    if (isCompanyPublicationForm) {
      const publiData = {
        ...publiFormData,
        organisateurId: data.companies[0].id,
      };
      handleClose01(publiData, setPubliFormData, fetchRows);
    }
    setIsCompanyPublicationForm(!isCompanyPublicationForm);
  };

  const clickHandlerPublicationFormCancel = () => {
    setPubliFormData(null);
    setIsCompanyPublicationForm(!isCompanyPublicationForm);
  };

  const dragStart = (event) => {
    document.body.style.cursor = "grab";
    setDragOrigin(event.target.id);
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const onMouseLeave = (event) => {
    event.preventDefault();
    document.body.style.cursor = "default";
  };

  const onDrop = (event) => {
    event.preventDefault();
    store.dispatch(
      insertPublication({
        originIndex: dragOrigin,
        destinationIndex: event.target.id,
      })
    );
    document.body.style.cursor = "default";
  };

  // const sortedPublications = data.publicationList ? JSON.parse(data.publicationList)
  // 	.map(index => publications.find(publication => publication.index === index)) : []

  const columns = [
    {
      field: "image",
      renderHeader: () => <strong>{"Image"}</strong>,
      flex: 2,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "grab" }}>
            <img
              alt=""
              onMouseLeave={onMouseLeave}
              onDrop={onDrop}
              onDragOver={allowDrop}
              id={params.row.id}
              draggable={true}
              onDragStart={dragStart}
              style={{
                width: "90px",
                margin: "1vw",
                padding: "0.25vw",
                height: "90px",
                objectFit: "cover",
              }}
              src={params.row.url}
            ></img>
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Description",
      flex: 5,
      renderHeader: () => <strong>{"Description"}</strong>,
      cellClassName: "theme--cell",
    },
    {
      field: "source",
      headerName: "Source",
      flex: 3,
      renderHeader: () => <strong>{"Source"}</strong>,
      cellClassName: "theme--cell",
    },
    {
      field: "delete",
      renderHeader: () => <strong>{"Supprimer"}</strong>,
      sortable: false,
      flex: 1.7,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          confirm({
            title: "ATTENTION",
            cancellationText: "Annuler",
            confirmationText: "Supprimer",
            description:
              "Vous allez supprimer définitivement cette publication",
          }).then(() => {
            API.graphql(
              graphqlOperation(deletePublication, {
                input: { id: params.row.id },
              })
            )
              .then((res) => {
                fetchRows();
              })
              .catch((err) => console.log(err));
            // store.dispatch(deletePublication({
            // 	id: params.row.id
            // }))
          });
        };
        return (
          <ColorButton onClick={onClick}>
            <DeleteIcon />
          </ColorButton>
        );
      },
    },
  ];

  return (
    <Box width="100%">
      <Box>
        <Box width="100%" margin="20px">
          <Typography variant="h4" fontSize="1.5rem" fontWeight={900}>
            {"Completez les formulaires «notre page» pour vous présenter sur www.pekoia.com".toUpperCase()}
          </Typography>
        </Box>
        <Box width="100%" margin="30px 0px 30px 20px">
          <Typography>
            À noter que le bon remplissage du formulaire «Informations» est
            obligatoire pour créer une première conférence ou un premier cycle
            de conférences sur Pekoia.
          </Typography>
        </Box>
      </Box>
      <ProfileAdvancement
        profileRef={profileRef}
        descRef={historyRef}
        publiRef={publiRef}
        fetchData={fetchData}
        user={user}
        isCompany={true}
      />
      <Box
        ref={profileRef}
        border={isCompanyForm ? `2px solid black` : "none"}
        borderRadius="20px"
        width="100%"
        padding="20px"
      >
        <Box>
          <Box borderBottom="1px solid black">
            <Typography variant="h4" fontSize="1.5rem" margin="10px">
              Informations
            </Typography>
          </Box>
          {isCompanyForm ? (
            <CompanyForm
              formData={formData}
              setFormData={setFormData}
              companyData={companyData}
            />
          ) : (
            <CompanyInfo companyData={companyData} />
          )}
        </Box>
        {isCompanyForm ? (
          <div>
            <StyledButton
              onClick={clickHandlerForm}
              style={{ marginBottom: "10px" }}
            >
              Sauvegarder
            </StyledButton>
            <StyledButton
              onClick={clickHandlerFormCancel}
              style={{ marginBottom: "10px" }}
            >
              Annuler
            </StyledButton>
          </div>
        ) : (
          <StyledButton
            onClick={clickHandlerForm}
            style={{ marginBottom: "10px" }}
          >
            Modifier
          </StyledButton>
        )}
      </Box>
      <Box
        ref={historyRef}
        border={isCompanyHistoryForm ? `2px solid black` : "none"}
        borderRadius="20px"
        width="100%"
        padding="20px"
      >
        <Box>
          <Box borderBottom="1px solid black">
            <Typography variant="h4" fontSize="1.5rem" margin="10px">
              A propos
            </Typography>
          </Box>
          {isCompanyHistoryForm ? (
            <CompanyDescForm
              descFormData={descFormData}
              setDescFormData={setDescFormData}
              companyData={companyData}
            />
          ) : (
            <CompanyDescInfo companyData={companyData} />
          )}
        </Box>
        {isCompanyHistoryForm ? (
          <div>
            <StyledButton
              onClick={clickHandlerHistoryForm}
              style={{ marginBottom: "10px" }}
            >
              Sauvegarder
            </StyledButton>
            <StyledButton
              onClick={clickHandlerHistoryFormCancel}
              style={{ marginBottom: "10px" }}
            >
              Annuler
            </StyledButton>
          </div>
        ) : (
          <StyledButton
            onClick={clickHandlerHistoryForm}
            style={{ marginBottom: "10px" }}
          >
            Modifier
          </StyledButton>
        )}
      </Box>
      <Box
        ref={publiRef}
        border={isCompanyPublicationForm ? `2px solid black` : "none"}
        borderRadius="20px"
        width="100%"
        padding="20px"
      >
        <Box borderBottom="1px solid black">
          <Typography variant="h4" fontSize="1.5rem" margin="10px">
            Publications
          </Typography>
        </Box>
        {isCompanyPublicationForm ? (
          <CompanyPublicationForm
            style={{ width: "64vw" }}
            formData={publiFormData}
            setFormData={setPubliFormData}
          />
        ) : null}
        {isCompanyPublicationForm ? (
          <div>
            <StyledButton
              onClick={clickHandlerPublicationForm}
              style={{ marginBottom: "10px" }}
            >
              Sauvegarder
            </StyledButton>
            <StyledButton
              onClick={clickHandlerPublicationFormCancel}
              style={{ marginBottom: "10px" }}
            >
              Annuler
            </StyledButton>
          </div>
        ) : (
          <StyledButton
            onClick={clickHandlerPublicationForm}
            style={{ marginBottom: "10px" }}
          >
            Ajouter
          </StyledButton>
        )}
        <Box>
          <Box
            bgcolor={colorGray}
            height="50vw"
            marginLeft="0vw"
            border={`1px solid ${grey[100]}`}
          >
            <DataGridPro
              rowHeight={80}
              localeText={localeText}
              onClick={() => {}}
              rows={rows}
              columns={columns}
              pageSize={5}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionCompany;

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "#1a5aa3",
  color: "#FFFFFF",
  height: "32px",
  borderRadius: "20px",
  fontSize: "0.85rem",
  margin: "5px",
  marginTop: "20px",
  "&:hover": {
    backgroundColor: grey[700],
  },
});
